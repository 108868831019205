import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { BIG, XLARGE } from 'app/theme/spacing';
import { isTablet, isWebResponsive } from '@constants/platform';

const styles = StyleSheet.create({
  logoImage: {
    height: 42,
    width: 125,
    // eslint-disable-next-line no-nested-ternary
    marginBottom: isWebResponsive ? BIG : isTablet ? 108 : XLARGE,
    alignSelf: 'center',
  },
  description: {
    marginTop: moderateScale(8),
    marginBottom: isTablet ? moderateScale(40) : moderateScale(32),
  },
});

export default styles;
