/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useEffect } from 'react';
import { ColorValue, View } from 'react-native';
import { NavigationContainer, DefaultTheme, ParamListBase } from '@react-navigation/native';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { SvgProps } from 'react-native-svg';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import CustomText from '@atoms/CustomText';
import { Colors, Fonts } from '@theme';
import Analytics from '@assets/svg/Analytics';
import { useTranslations } from '@hooks/useTranslation';
import { useMyProfile } from '@services/useMyProfile';
import { BalanceIcon } from '@assets/svg/balanceIcon';
import MyGames from '@assets/svg/MyGames';
import { DESKTOP_CONTAINER_WIDTH, SCREEN_WIDTH } from '@constants/platform';
import { NotAvailableModal } from '@organisms/NaModal';
import { moderateScale } from '@utils/scaling';

import { BalanceScreen } from './Balance';
import { AnalyticsScreen } from './Analytics';
import { styles } from './styles';

const Drawer = createDrawerNavigator();

const drawerItemIcon = (
  Icon: FC<SvgProps>,
  nonFocusColor?: ColorValue,
  focused?: boolean,
  focusColor: ColorValue = Colors.limeGreen
) => (
  <View style={{ width: moderateScale(20), height: moderateScale(20) }}>
    <Icon fill={focused ? focusColor : nonFocusColor} />
  </View>
);

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const i18n = useTranslations();

  return (
    <DrawerContentScrollView {...props} showsVerticalScrollIndicator={false} style={styles.drawerContainer}>
      <CustomText
        size="msmall"
        align="left"
        weight="interRegular"
        color="greySix"
        customStyle={styles.drawerTitle}>
        {i18n.t('dashboard.sidebar.title')}
      </CustomText>
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
};

const ComingSoon = () => <View style={{ backgroundColor: Colors.black }} />;

const BalanceLabel = ({ disabled, focused }: { focused: boolean; disabled?: boolean }) => {
  const i18n = useTranslations();
  return (
    <View
      style={[
        { flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' },
        disabled && styles.drawerItemDisable,
      ]}>
      <CustomText weight="interRegular" color="white">
        {i18n.t('dashboard.sidebar.balance').toString()}
      </CustomText>
      <CustomText weight="interRegular" color={focused ? 'limeGreen' : 'white'} size="msmall">
        BETA
      </CustomText>
    </View>
  );
};
// const BalanceTabs: ITabs[] = [
//   { title: 'dashboard.my_earnings.title', children: <BalanceScreen /> },
//   { title: 'dashboard.sidebar.payments_and_billing', disabled: true },
// ];

// const CreatorIncomes = (): JSX.Element => <CustomTabs tabs={BalanceTabs} />;

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    border: 'rgb(0, 0, 0)',
  },
};

export const Dashboard = ({
  navigation,
}: {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}): JSX.Element => {
  // hooks
  const { navigate } = navigation;
  const i18n = useTranslations();
  const { data } = useMyProfile();

  // effects
  useEffect(() => {
    if (data && data?.type !== 'CREATOR') {
      navigate('Home');
    }
  }, [data, navigate]);

  if (SCREEN_WIDTH < DESKTOP_CONTAINER_WIDTH) {
    return <NotAvailableModal />;
  }

  // render
  return (
    <NavigationContainer independent theme={MyTheme}>
      <Drawer.Navigator
        screenOptions={{
          drawerType: 'permanent',
          headerShown: false,
          drawerActiveTintColor: Colors.limeGreen,
          drawerInactiveTintColor: Colors.greySix,
          drawerItemStyle: styles.drawerItem,

          drawerLabelStyle: { width: '100%', alignSelf: 'flex-start', fontFamily: Fonts.INTER_REGULAR },
        }}
        drawerContent={CustomDrawerContent}>
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.analytics')}
          component={AnalyticsScreen}
          options={{
            drawerIcon: ({ focused }) => drawerItemIcon(Analytics, '#777E91', focused, Colors.limeGreen),
            drawerLabel: () => (
              <CustomText weight="interRegular" align="left">
                {i18n.t('dashboard.sidebar.analytics').toString()}
              </CustomText>
            ),
          }}
        />
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.balance')}
          component={BalanceScreen}
          options={{
            drawerIcon: ({ focused }) => drawerItemIcon(BalanceIcon, '#777E91', focused, Colors.limeGreen),
            drawerLabel: (props) => <BalanceLabel focused={props.focused} />,
          }}
        />
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.coming_soon_in_gato')}
          component={ComingSoon}
          options={{ drawerItemStyle: styles.drawerItemDisable }}
        />
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.my_games')}
          component={AnalyticsScreen}
          options={{
            drawerIcon: ({ focused }) => drawerItemIcon(MyGames, '#777E91', focused, Colors.limeGreen),
            drawerLabel: () => (
              <CustomText weight="interRegular" align="left" customStyle={styles.drawerItemDisable}>
                {i18n.t('dashboard.sidebar.my_games').toString()}
              </CustomText>
            ),
            drawerItemStyle: styles.drawerItemDisable,
          }}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};
