export interface IEarningStatCardProps {
  title: string;
  value: number | undefined;
  format?: BalanceStatsEnum;
}

export enum BalanceStatsEnum {
  DECIMAL_NUMBER = 'decimal',
  TIME_NUMBER = 'time',
  CURRENCY_NUMBER = 'currency',
}
