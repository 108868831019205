import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { Keyboard, TextInput } from 'react-native';

import SearchIcon from '@assets/svg/SearchIcon';
import Field from '@molecules/Field';
import { useTranslations } from '@hooks/useTranslation';
import { useDebounce } from '@hooks/useDebounce';
import { useSearch } from '@services/useSearch';
import { useStore, setGameIdHandler, setSearchResultsHandler } from '@store/index';
import { useMyProfile } from '@services/useMyProfile';
import { isMobile } from '@constants/platform';
import { getGameSlug } from '@utils/common';

import { styles } from './styles';

const Search = () => {
  const i18n = useTranslations();
  const navigation = useNavigation();
  const { control, getValues, watch, setValue } = useForm({ mode: 'onChange' });
  const { data: profileData } = useMyProfile();
  const searchService = useSearch();

  const setGameId = useStore(setGameIdHandler);
  const setSearchResults = useStore(setSearchResultsHandler);

  const [results, setResults] = useState([]);
  const [hasSearchResults, setHasSearchResults] = useState(true);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const inputRef = useRef<TextInput>(null);

  const clearField = () => {
    setValue('search', '');
    setResults([]);
  };

  const search = watch('search');

  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    setResults([]);
    setHasSearchResults(true);

    if (search?.length <= 0) {
      clearField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setHasSearchResults]);

  const handleGame = (id: number, name: string) => {
    setGameId(id);
    clearField();

    if (isMobile) {
      const item = {
        id,
        name,
      };
      const navigateAction = CommonActions.navigate({
        name: 'GamePage',
        params: {
          game: item,
        },
        key: `GamePage-${item?.id}`,
      });
      return navigation.dispatch(navigateAction);
    }

    const gameSlug = getGameSlug(name);

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: gameSlug,
      },
      key: `GamePage/${gameSlug}`,
    });
    return navigation.dispatch(navigateAction);
  };

  const handleUser = (user) => {
    clearField();

    const isOwnProfile = profileData?.userName === user?.userName;

    if (isOwnProfile) {
      if (isMobile) {
        return navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Main',
              state: {
                routes: [
                  {
                    name: 'MyProfile',
                  },
                ],
              },
            },
          ],
        });
      }

      return navigation.navigate('MyProfile');
    }

    if (isMobile) {
      const userId = user?.id;
      const navigateAction = CommonActions.navigate({
        name: 'UserProfile',
        params: {
          userId,
        },
        key: `UserProfile-${userId}`,
      });
      return navigation.dispatch(navigateAction);
    }

    const userName = user?.userName;
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName,
      },
      key: `UserProfile-${userName}`,
    });
    return navigation.dispatch(navigateAction);
  };

  function filterArrays(games, users) {

    if (games.length + users.length > 4)
    {
      if (!games.length) return users.slice(0, 4);
      if (!users.length) return games.slice(0, 4);
      if (games.length == users.length)
        return games.slice(0, 2).concat(users.slice(0, 2));
      if (games.length > users.length)
        return games.slice(0, 3).concat(users.slice(0, 1));
      return games.slice(0, 1).concat(users.slice(0, 3));
    }

    return games.concat(users);
  }

  useEffect(() => {
    if (debouncedSearch && search?.length >= 3) {
      Keyboard.dismiss();

      searchService.mutate(debouncedSearch, {
        onSuccess: (data) => {
          const totalItems = data.response.games.results.length + data.response.users.results.length;
          setHasMoreItems(totalItems > 4);

          const games = data.response.games.results.map((game) => ({
            id: game?.id,
            title: game?.name,
            subtitle: 'Game',
            image: game?.principalImage,
            genre: game?.genre?.name,
            onPress: () => handleGame(game?.id, game?.name),
          }));
          const users = data.response.users.results.map((user) => ({
            id: user?.id,
            title: user?.fullName,
            subtitle: `@${user?.userName}`,
            image: user?.avatar,
            username: user?.userName,
            type: user?.type,
            onPress: () => handleUser(user),
          }));

          const searchState = {
            games,
            users,
          };
          setSearchResults(searchState);

          const dataFiltered = filterArrays(games, users);

          if (dataFiltered?.length > 0) {
            setResults(dataFiltered);
          } else {
            setHasSearchResults(false);
          }
        },
        onError: () => {
          setHasSearchResults(false);
        },
        onSettled: () => {
          // setTimeout(() => {
          //   if (inputRef.current) {
          //     inputRef.current.focus();
          //   }
          // }, 40);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Field
      icon={SearchIcon}
      name="search"
      control={control}
      getValues={getValues}
      watch={watch}
      error={{}}
      placeholder={i18n.t('search_navbar.placeholder')}
      containerStyles={styles.container}
      removeMarginBottom
      isSearchInput
      clearField={clearField}
      searchResults={results}
      hasSearchResults={hasSearchResults}
      hasMoreItems={hasMoreItems}
      searchIsLoading={searchService.isLoading}
      maxLength={80}
      customRef={inputRef}
    />
  );
};

export default Search;
