export type SocialMedia = 'email' | 'instagram' | 'facebook' | 'twitter' | 'discord';

export const OUR_SOCIAL_MEDIA: Record<SocialMedia, string> = {
  email: 'mailto:hello@gato.us',
  instagram: 'https://instagram.com/gatoisus',
  facebook: 'https://facebook.com/gatoisus',
  twitter: 'https://twitter.com/gatoisus',
  discord: 'https://discord.gg/6Tve36beug',
};

export interface SectionProps {
  isSmallLayout?: boolean;
}
