import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';

import ItemDivider from '@atoms/ItemDivider';
import { AvailableFundsCard } from '@molecules/AvailableFunds';
import { TransactionsHistoryCard } from '@molecules/TransactionHistory';
import { EarningStatCard } from '@molecules/EarningStatCard';
import { useTranslations } from '@hooks/useTranslation';
import { DESKTOP_CONTAINER_WIDTH, IS_HIGH_SCREEN, SCREEN_WIDTH } from '@constants/platform';
import CustomText from '@atoms/CustomText';
import { useGetGamesByCreator } from '@services/useGetGamesByCreator';
import { useUserData } from '@services/useUserData';
import { authTokenSelector, useStore } from '@store/index';
import { BalanceStatsEnum } from '@molecules/EarningStatCard/types';
import { useDashboardEarnings } from '@services/useDashboardEarnings';
import { Colors } from '@theme';

import { yearsArray } from '../helper';
import { SelectTheme, customSelectStyles } from '../Analytics/styles';

import { styles } from './styles';
import { BalanceFilters } from './types';

const FILTERS_INIT_VALUE: BalanceFilters = {
  gameId: '',
  year: new Date().getFullYear().toString(),
  month: new Date().getMonth().toString(),
};

export const BalanceScreen = (): JSX.Element => {
  // main dependencies boilerplate
  const i18n = useTranslations();
  // const currentDate: Date = new Date();
  const { data: earnings, isLoading } = useDashboardEarnings();
  const authToken = useStore(authTokenSelector);
  const userData = useUserData(authToken || '');
  // const [yearSelected, setYearSelected] = useState<string | null>(currentDate.getFullYear().toString());
  const [filters, setFilters] = useState<BalanceFilters>(FILTERS_INIT_VALUE);

  // constants
  const creatorId: number | undefined = userData?.data?.id;
  // const translatedMonths: CalendarType[] = allMonthsArray.map((m) => {
  //   const all: typeof m = {
  //     label: i18n.t(m.label),
  //     value: m.value,
  //   };
  //   return all;
  // });
  // const monthActualValue: string = (currentDate.getMonth() + 1).toString().padStart(2, '0');

  // const MONTHS: CalendarType[] =
  //   currentDate.getFullYear() === Number(yearSelected)
  //     ? translatedMonths.filter((item) => item.value <= monthActualValue)
  //     : translatedMonths;

  // const monthActualLabel: CalendarType | undefined = MONTHS.find((month) => month.value === monthActualValue);
  const gameHasEarningStats = Boolean(earnings?.yearly_balance[filters.year]?.games[filters.gameId]);
  const earningsValue: number | undefined = !filters.gameId
    ? earnings?.yearly_balance[filters.year]?.earnings
    : 0;
  const sessionsValue: number | undefined = !filters.gameId
    ? earnings?.yearly_balance[filters.year]?.total_sessions
    : 0;
  const minutesPlayedValue: number | undefined = !filters.gameId
    ? earnings?.yearly_balance[filters.year]?.minutes_played
    : 0;
  const availableFunds: number = earnings?.all_time_totals.earnings || 0;
  // states
  const [games, setGames] = useState<unknown[]>([]);
  const [gameSelected, setGameSelected] = useState<string>('');
  // const [monthSelected, setMonthSelected] = useState<string | null>(
  //   i18n.t(monthActualLabel?.label || '') || ''
  // );

  // hooks
  const { control } = useForm();
  const { data: creator } = useGetGamesByCreator(Number(creatorId), 'CREATOR');

  // functions
  function updateState(key: 'year' | 'month' | 'gameId', value: string): void {
    setFilters({
      ...filters,
      [key]: value,
    });
  }

  // side effects
  useEffect(() => {
    if (creator) {
      const creatorGames = creator[0]?.games.map((game: any) => ({
        value: game.id,
        label: game.name,
      }));
      creatorGames.unshift({
        value: '',
        label: i18n.t('dashboard.my_earnings.balance_info.all_games_filter'),
      });

      setGames(creatorGames);
    }
  }, [creator]);

  // render
  if (isLoading) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size={72} color={Colors.limeGreen} />
      </View>
    );
  }
  if (SCREEN_WIDTH < DESKTOP_CONTAINER_WIDTH) {
    return (
      <View style={styles.mainContainer}>
        <CustomText>{i18n.t('dashboard.my_earnings.NA_title')}</CustomText>
      </View>
    );
  }

  return (
    <ScrollView style={styles.mainContainer}>
      <View style={styles.container}>
        <CustomText weight="interSemi" align="left" size={IS_HIGH_SCREEN ? 'xlarge' : 'xbig'}>
          {i18n.t('dashboard.my_earnings.title')}
        </CustomText>
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <AvailableFundsCard value={availableFunds} />
          <TransactionsHistoryCard />
        </View>
      </View>
      <View style={styles.divider}>
        <ItemDivider />
      </View>
      <View style={styles.container}>
        <View style={[styles.cardsWrapper, { zIndex: 99 }]}>
          <CustomText weight="interSemi" align="left" size={IS_HIGH_SCREEN ? 'xlarge' : 'xbig'}>
            {i18n.t('dashboard.my_earnings.balance_info.title')}
          </CustomText>
          {/* selects here */}
          <View style={styles.cardsWrapper}>
            <View style={styles.select}>
              <Controller
                name="Games"
                control={control}
                render={({ field }) => (
                  <Select
                    options={games}
                    placeholder={
                      gameSelected || i18n.t('dashboard.my_earnings.balance_info.all_games_filter')
                    }
                    menuPlacement="top"
                    maxMenuHeight={200}
                    styles={customSelectStyles}
                    theme={SelectTheme}
                    onChange={(e: SingleValue<any>) => {
                      setGameSelected(e?.label);
                      field.onChange(e?.value);
                      updateState('gameId', String(e?.value));
                    }}
                  />
                )}
              />
            </View>
            {/* ENABLE THIS WHEN THE PAYMENTS RESPONSE IS READY TO FILTER BY MONTH */}
            {/* <View style={styles.select}>
              <Controller
                name="Month"
                control={control}
                defaultValue={monthSelected}
                render={({ field }) => (
                  <Select
                    options={MONTHS}
                    placeholder={monthSelected}
                    styles={customSelectStyles}
                    menuPlacement="top"
                    maxMenuHeight={200}
                    theme={SelectTheme}
                    onChange={(e) => {
                      field.onChange(e?.value);
                      setMonthSelected(e?.label || '');
                      updateState('month', String(e?.value));
                    }}
                  />
                )}
              />
            </View> */}
            <View style={styles.select}>
              <Controller
                name="Year"
                control={control}
                defaultValue={new Date().getFullYear().toString()}
                render={({ field }) => (
                  <Select
                    options={yearsArray}
                    placeholder={new Date().getFullYear().toString()}
                    menuPlacement="top"
                    isSearchable={false}
                    styles={customSelectStyles}
                    theme={SelectTheme}
                    onChange={(e) => {
                      field.onChange(e?.value);
                      // setYearSelected(e?.label || '');
                      updateState('year', String(e?.value));
                    }}
                  />
                )}
              />
            </View>
          </View>
        </View>
        <View style={[styles.cardsWrapper, { zIndex: 1 }]}>
          <EarningStatCard
            title={i18n.t('dashboard.my_earnings.balance_info.total_profit')}
            value={
              filters.gameId && gameHasEarningStats
                ? earnings?.yearly_balance[filters.year]?.games[filters.gameId].earnings || 0
                : earningsValue
            }
            format={BalanceStatsEnum.CURRENCY_NUMBER}
          />
          <EarningStatCard
            title={i18n.t('dashboard.my_earnings.balance_info.total_games_played')}
            value={
              filters.gameId && gameHasEarningStats
                ? earnings?.yearly_balance[filters.year]?.games[filters.gameId].total_sessions || 0
                : sessionsValue
            }
            format={BalanceStatsEnum.DECIMAL_NUMBER}
          />
          <EarningStatCard
            title={i18n.t('dashboard.my_earnings.balance_info.total_playtime')}
            value={
              filters.gameId && gameHasEarningStats
                ? earnings?.yearly_balance[filters.year]?.games[filters.gameId].minutes_played || 0
                : minutesPlayedValue
            }
            format={BalanceStatsEnum.TIME_NUMBER}
          />
        </View>
      </View>
    </ScrollView>
  );
};
