import { View, Image, ImageSourcePropType, Pressable } from 'react-native';
import React, { FC } from 'react';
import { StackActions, useNavigation } from '@react-navigation/core';
import { MotiView } from 'moti';

import { Game } from '@interfaces/gameDetail';
import CustomText from '@atoms/CustomText';
import { LanguagesEnum, getLanguage } from '@utils/getLanguage';
import Button from '@atoms/Button';
import { useTranslations } from '@hooks/useTranslation';
import { setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { getGameSlug } from '@utils/common';

import styles from './styles';

export const MightGameCard: FC<Game> = (props): JSX.Element => {
  // constants
  // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
  const { name, description_en, description_pt, description_es, principalImage } = props;
  const source: ImageSourcePropType = { uri: principalImage || '' };

  // hooks
  const lang = getLanguage();
  const i18n = useTranslations();
  const { dispatch } = useNavigation();
  const gameSlug = getGameSlug(name);

  // store
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);

  // functions
  function handlePress(): void {
    const navigateAction = StackActions.push('GamePage', {
      name: gameSlug,
      autoStartGame: true,
    });
    dispatch(StackActions.pop());
    dispatch(navigateAction);
    toggleModal();
    setModalInfo(undefined);
  }

  function selectDescription(): string {
    if (lang === LanguagesEnum.PORTUGUESE_LANG) {
      // eslint-disable-next-line camelcase
      return description_pt;
    }
    if (lang === LanguagesEnum.SPANISH_LANG) {
      // eslint-disable-next-line camelcase
      return description_es;
    }
    // eslint-disable-next-line camelcase
    return description_en;
  }
  // render
  return (
    <MotiView
      style={styles.card}
      from={{ bottom: -50, opacity: 0 }}
      animate={{ bottom: 0, opacity: 1 }}
      delay={300}>
      <Pressable onPress={handlePress}>
        <Image source={source} style={styles.img} />
      </Pressable>
      <View style={styles.descriptionWrapper}>
        <Pressable onPress={handlePress}>
          <CustomText color="white" align="left" size="lmedium">
            {name}
          </CustomText>
        </Pressable>
        <CustomText
          color="white"
          align="left"
          size="msmall"
          weight="interRegular"
          customStyle={styles.description}>
          {selectDescription()}
        </CustomText>
        <Button
          onPress={handlePress}
          secondary
          customTextStyle={styles.buttonText}
          customStyle={styles.button}
          // icon={<PlayNowIcon />}
        >
          {i18n.t('game_page.play_game')}
        </Button>
      </View>
    </MotiView>
  );
};
