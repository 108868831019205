import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { IS_HIGH_SCREEN } from '@constants/platform';

export const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.black,
    borderWidth: 1,
  },
  container: {
    paddingHorizontal: moderateScale(35),
    paddingVertical: moderateScale(40),
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: Colors.black,
    paddingHorizontal: moderateScale(20),
    minHeight: moderateScale(90),
  },
  divider: { marginBottom: IS_HIGH_SCREEN ? 30 : 15, marginTop: IS_HIGH_SCREEN ? 30 : 0 },
  cardsWrapper: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  select: {
    marginLeft: 20,
    zIndex: 999,
  },
});
