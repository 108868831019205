import React, { FC } from 'react';

import GameBanner from '@molecules/GameBanner';
import links from '@constants/links';
import { IS_LG, IS_MD, IS_SM, IS_XL } from '@constants/platform';
import { GameBannerItem } from '@molecules/GameBanner/types';

import { GameQuestBannerProps } from './types';

const GameQuestBanner: FC<GameQuestBannerProps> = ({ dimensions, isPrincipal }): JSX.Element => {
  let image: string = links.gameQuestMobileBanner;

  if (IS_SM) {
    image = links.gameQuestTabletBanner;
  }

  if (IS_MD || IS_LG || IS_XL) {
    image = links.gameQuestDesktopBanner;
  }

  const item: GameBannerItem = {
    id: 0,
    principalImage: links.gameQuestMobileBanner,
    principalImage_web: image,
    link: links.gameQuestHomeLink,
  };

  return <GameBanner item={item} dimentions={dimensions} ppal={isPrincipal} />;
};

export default GameQuestBanner;
