import { StyleSheet } from 'react-native';
import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig, Theme } from 'react-select';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { IS_HIGH_SCREEN } from '@constants/platform';

import { GraphicType } from '../helper';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.socialBackground,
    padding: 50,
    borderWidth: 1,
    borderColor: Colors.socialBackground,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    marginTop: moderateScale(24),
    paddingHorizontal: moderateScale(20),
    borderRadius: 16,
    minHeight: moderateScale(90),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 999,
  },
  select: {
    marginLeft: 20,
    zIndex: 999,
  },
  button: {
    marginLeft: Spacing.SMALL,
  },
  graphics: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  graphic: {
    marginTop: 25,
    backgroundColor: Colors.darkGrey,
    borderRadius: 16,
    padding: 40,
    width: '100%',
  },
  graphicHalf: {
    width: '49%',
  },
  devicesContainer: {
    marginTop: 50,
  },
  inline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 99,
  },
  inlineToolTip: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  device: {
    marginLeft: 20,
  },
  square: {
    width: 12,
    height: 12,
    borderRadius: 2,
    marginRight: 10,
  },
  label: {
    marginRight: 26,
  },
  tooltip: {
    minWidth: 152,
    minHeight: 50,
    borderRadius: 8,
    padding: 8,
    backgroundColor: Colors.greyFour,
  },
  tooltipSquare: {
    width: 12,
    height: 12,
    borderRadius: 4,
    backgroundColor: Colors.limeGreen,
    marginRight: 4,
  },
  tooltipDate: {
    marginBottom: 10,
  },
});

export const customSelectStyles: StylesConfig<GraphicType, false, GroupBase<GraphicType>> = {
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    fontFamily: 'InterTight-SemiBold',
    fontSize: IS_HIGH_SCREEN ? 20 : 16,
    border: 0,
    paddingLeft: 8,
    width: 250,
    marginTop: 4,
    zIndex: 99,
  }),
  option: (provided: CSSObjectWithLabel, state: OptionProps<GraphicType, false, GroupBase<GraphicType>>) => ({
    ...provided,
    fontFamily: 'InterTight-SemiBold',
    fontSize: 16,
    borderRadius: 4,
    borderBottom: 0,
    color: state.isSelected ? 'white' : 'white',
    zIndex: 99,
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    flex: 1,
    maxWidth: 250,
    zIndex: 99,
  }),
};

export const SelectTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    neutral0: '#1A1D1F',
    neutral50: '#A4ADC2',
    primary: '#A4ADC2',
    primary25: 'gray',
    neutral20: '#FFFFFF12',
    neutral80: '#A4ADC2',
  },
});
