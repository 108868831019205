import React, { useEffect, useRef, useState } from 'react';

interface IUseGPT {
  path: string;
  size: number[];
  id: string;
}

const useGPT = ({ path, size, id }: IUseGPT) => {
  // States
  const [adError, setAdError] = useState<boolean>(false);
  const adSlot = useRef(null);

  // Effects
  useEffect(() => {
    const googletag = window.googletag || {
      cmd: [],
      pubads: () => ({
        getSlots: () => [],
      }),
    };
    googletag.cmd = googletag.cmd || [];

    adSlot.current = googletag
      ?.pubads()
      ?.getSlots()
      ?.find((slot: any) => {
        if (slot.getSlotElementId() === id) {
          return slot;
        }

        return null;
      });

    console.log(`[GPT]: ${id} slot detected.`, adSlot.current);

    googletag.cmd.push(() => {
      try {
        // if (!adSlot.current) {
        //   adSlot.current = googletag.defineSlot(path, size, id).addService(googletag.pubads());
        //   console.log('🌈refreshing adSlot', adSlot.current);
        // }
        // googletag.pubads().disableInitialLoad();
        // googletag.pubads().enableSingleRequest();
        // googletag.pubads().collapseEmptyDivs();
        // googletag.enableServices();
      } catch (e) {
        setAdError(true);
      }
    });

    googletag.cmd.push(() => {
      try {
        googletag.display(id);
        setAdError(false);
      } catch (e) {
        console.error(`[GPT]: ${id} failed to display.`, e);
        setAdError(true);
      }
    });

    return () => {
      // eslint-disable-next-line prefer-arrow-callback
      // googletag.cmd.push(function () {
      //   if (adSlot.current) {
      //     console.log('🧨destroying adSlot', adSlot.current);
      //     googletag.destroySlots([adSlot.current]); // Only destroy this slot
      //     adSlot.current = null;
      //   }
      //   setAdError(false);
      // });
    };
  }, []);

  return adError;
};

export default useGPT;
