import React, { FC } from 'react';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { numberToCurrency } from '@utils/numberToCurrency';
import { BankIcon } from '@assets/svg/bankIcon';
import { IconBadge } from '@molecules/IconBadge';
import { InformationIcon } from '@assets/svg/InformationIcon';
import { IS_HIGH_SCREEN } from '@constants/platform';

import { styles } from './styles';
import { IAvailableFundsProps } from './types';

export const AvailableFundsCard: FC<IAvailableFundsProps> = ({ value }): JSX.Element => {
  // hooks
  const i18n = useTranslations();
  // render
  return (
    <View style={styles.mainContainer}>
      <View style={styles.flexWrapper}>
        <IconBadge icon={<BankIcon />} />
        <CustomText
          weight="interBold"
          align="left"
          size={IS_HIGH_SCREEN ? 'xbig' : 'xmedium'}
          customStyle={{ marginHorizontal: 10 }}>
          {i18n.t('dashboard.my_earnings.funds.available_funds')}
        </CustomText>
      </View>
      <CustomText weight="interBold" align="left" size="giant" customStyle={{ marginVertical: 10 }}>
        {numberToCurrency(Number(value))}
      </CustomText>
      <View style={[styles.flexWrapper, { opacity: value < 100 ? 1 : 0 }]}>
        <InformationIcon />
        <CustomText
          align="left"
          size={IS_HIGH_SCREEN ? 'large' : 'medium'}
          color="warning"
          customStyle={{ marginHorizontal: 5 }}>
          {i18n.t('dashboard.my_earnings.funds.minimun_withdrawal_warning')}
        </CustomText>
      </View>
    </View>
  );
};
