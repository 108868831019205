// Forms
const uploadingGameLink = 'https://app.gato.us/upload'; // Link for upload game
const gameQuestUploadGameLink = 'https://app.gato.us/upload'; // Link for upload game
const gameQuestLink = 'https://gamequest.gato.us/'; // Link for upload game

// Banners
const christmasDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/gato-christmas-2023/christmas-banner-desktop.png';
const christmasMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/gato-christmas-2023/christmas-banner-mobile.png';
const christmasTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/gato-christmas-2023/christmas-banner-tablet.png';

const gameQuestDesktopGIFBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2023/GGQ_Desktop.gif';
const gameQuestDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+GGQ+II+DESKTOP+1.png';
const gameQuestMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+GGQ+II+MOBILE+1.png';
const gameQuestTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+GGQ+II+TABLET+1.png';
const gameQuestGamePageBannerEN =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+Participa+EN.png';
const gameQuestGamePageBannerES =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+Participa+ES.png';
const gameQuestGamePageBannerENDesktop =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+Participa+EN+DESKTOP.png';
const gameQuestGamePageBannerESDesktop =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2/Banner+Participa+ES+DESKTOP.png';

const halloweenDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/halloween-2023/halloween-banner-desktop.png';
const halloweenMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/halloween-2023/halloween-banner-mobile.png';
const halloweenTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/halloween-2023/halloween-banner-tablet.png';

const avatarsMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/create-your-avatar-nov-23/avatars-banner-mobile.png';
const avatarsDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/create-your-avatar-nov-23/avatars-banner-desktop.png';
const avatarsTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/create-your-avatar-nov-23/avatars-banner-tablet.png';

// Sites
const gameQuestHomeLink = 'https://gamequest.gato.us/';
const gameQuestVoteLink = `${gameQuestHomeLink}vote/`;
const gatoAboutLink = 'https://gamequest.gato.us/about';
const avatarsGatoLink = 'https://avatars.gato.us/';
const gatoBlog = 'https://blog.gato.us/';
const links = {
  uploadingGameLink,
  gameQuestUploadGameLink,
  gameQuestLink,
  gameQuestDesktopBanner,
  gameQuestDesktopGIFBanner,
  gameQuestMobileBanner,
  gameQuestTabletBanner,
  gameQuestHomeLink,
  gameQuestVoteLink,
  halloweenDesktopBanner,
  halloweenMobileBanner,
  halloweenTabletBanner,
  avatarsGatoLink,
  gatoAboutLink,
  avatarsMobileBanner,
  avatarsDesktopBanner,
  avatarsTabletBanner,
  christmasTabletBanner,
  christmasMobileBanner,
  christmasDesktopBanner,
  gatoBlog,
  gameQuestGamePageBannerEN,
  gameQuestGamePageBannerES,
  gameQuestGamePageBannerENDesktop,
  gameQuestGamePageBannerESDesktop,
};

export default links;
