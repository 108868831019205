import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { IS_HIGH_SCREEN } from '@constants/platform';

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: moderateScale(16),
    backgroundColor: Colors.darkGrey,
    borderRadius: 16,
    paddingVertical: moderateScale(40),
    paddingHorizontal: moderateScale(64),
    width: '48%',
    minWidth: moderateScale(405),
    maxWidth: moderateScale(600),
    height: IS_HIGH_SCREEN ? moderateScale(480) : moderateScale(230),
    justifyContent: 'space-between',
  },
  flexWrapper: { flexDirection: 'row', alignItems: 'center' },
  emptyHistoryContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.disabled,
    width: '100%',
    minHeight: moderateScale(110),
    height: IS_HIGH_SCREEN ? '80%' : 'auto',
    marginVertical: moderateScale(10),
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  historyContainer: {
    width: '100%',
    minHeight: moderateScale(110),
  },
  image: {
    alignSelf: 'center',
    width: IS_HIGH_SCREEN ? moderateScale(80) : moderateScale(48),
    height: IS_HIGH_SCREEN ? moderateScale(80) : moderateScale(48),
  },
});
