import { useEffect, useState } from 'react';

export enum OrientationEnum {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}
export default function useScreenOrientation(): OrientationEnum {
  const { PORTRAIT, LANDSCAPE } = OrientationEnum;
  const [orientation, setOrientation] = useState<OrientationEnum>(getOrientation());

  function getOrientation() {
    if (window.innerHeight > window.innerWidth) {
      return PORTRAIT;
    }

    return LANDSCAPE;
  }

  // Function to handle orientation changes
  function handleOrientationChange() {
    setOrientation(getOrientation());
  }

  // Effect to subscribe to orientation changes
  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return orientation;
}
