import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { AxiosError } from 'axios';

import Button from '@atoms/Button';
import { FooterButton } from '@atoms/FooterButton';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { useEditUserInfo } from '@services/useEditUserInfo';
import { authTokenSelector, setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import toast from '@utils/toast';
import { passwordRegex } from '@utils/validations';
import { RootStackNavigator } from 'app/navigation/types';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';

import { styles } from '../styles';
import { ScreenTitle } from '..';

type ChangePasswordProps = NativeStackScreenProps<RootStackNavigator, 'ChangePassword'>;

interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const ChangePassword = ({ navigation }: ChangePasswordProps) => {
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const authToken = useStore(authTokenSelector);
  const i18n = useTranslations();
  const updateInfo = useEditUserInfo(authToken || '');

  const {
    control,
    getValues,
    watch,
    setError,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<ChangePasswordParams>({ mode: 'onChange' });

  const passwordRef = React.useRef({});
  passwordRef.current = watch('newPassword', '');

  const oldPassword = watch('oldPassword');
  const newPassword = watch('newPassword');
  const repeatNewPassword = watch('repeatNewPassword');

  const isDisabled = !newPassword || !repeatNewPassword || Boolean(errors.repeatNewPassword?.message);

  function handleGoBack(): void {
    if (isWebResponsive) {
      navigation.goBack();
      return;
    }
    navigation.replace('AccountSettings');
  }

  const cancelChangePassword = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const onSubmit = (values: ChangePasswordParams) => {
    cancelChangePassword();
    const dataToSend = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword
    };
    updateInfo.mutate(dataToSend, {
      onSuccess: () => {
        toast.success({ icon: 'success', title: i18n.t('account_settings.changes_saved') });
        handleGoBack();
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const code = error?.response?.data.code;
          const message = i18n.t(error?.response?.data.translate);

          if (code === 'PASSWORD_REQUIRED' || code === 'INVALID_PASSWORD') {
            setError('oldPassword', { message });
          } else {
            toast.danger({ icon: 'error', title: i18n.t('account_settings.changes_not_saved') });
            handleGoBack();
          }
        }
      },
    });
  };

  const handleChangePassword = () => {
    setModalInfo({
      message: i18n.t('account_settings.change_confirm_message'),
      confirmText: i18n.t('account_settings.change_confirm_text'),
      cancelText: i18n.t('account_settings.change_cancel_text'),
      confirmAction: handleSubmit(onSubmit),
      cancelAction: () => cancelChangePassword(),
      bottomFixed: false
    });
    toggleModal();
  };

  return (
    <Layout
      padding={isMobile}
      hasBottomCTAS={
        <FooterButton>
          <Button
            loading={updateInfo.isLoading}
            disabled={isDisabled}
            customStyle={styles.footerButton}
            customTextStyle={styles.footerButtonText}
            onPress={handleChangePassword}>
            {i18n.t('account_settings.change')}
          </Button>
        </FooterButton>
      }
      verticallyCentered={isDesktop}>
      <View style={styles.screenContainer}>
        <View style={styles.screenInnerContainer}>
          {isWebResponsive ? (
            <View style={styles.goBack}>
              <GoBackButton goBackAction={handleGoBack} />
            </View>
          ) : null}
          <ScreenTitle>{i18n.t('account_settings.change_password')}</ScreenTitle>
          <View style={styles.formBody}>
            <Field
              icon={PasswordInputIcon}
              name="oldPassword"
              control={control}
              placeholder={i18n.t('account_settings.current_password_placeholder').toString()}
              rules={{
                pattern: {
                  value: passwordRegex,
                  message: i18n.t('register.password_min_lenght_error')
                }
              }}
              secure
              getValues={getValues}
              error={errors}
            />
            <Field
              icon={PasswordInputIcon}
              name="newPassword"
              control={control}
              placeholder={i18n.t('account_settings.new_password_placeholder').toString()}
              rules={{
                required: i18n.t('register.required').toString(),
                pattern: {
                  value: passwordRegex,
                  message: i18n.t('register.password_min_lenght_error')
                }
              }}
              secure
              focusText={i18n.t('register.password_text').toString()}
              getValues={getValues}
              error={errors}
              onFocus={() => {
                setValue('repeatNewPassword', '');
              }}
            />
            <Field
              icon={PasswordInputIcon}
              name="repeatNewPassword"
              error={errors}
              getValues={getValues}
              secure
              control={control}
              placeholder={i18n.t('account_settings.new_password_confirm_placeholder').toString()}
              rules={{
                required: i18n.t('register.required').toString(),
                validate: (value) =>
                  value === passwordRef.current || i18n.t('register.repeat_password_pattern_error').toString()
              }}
            />
            {isDesktop && !isWebResponsive ? (
              <Button
                loading={updateInfo.isLoading}
                disabled={isDisabled}
                customStyle={styles.footerButton}
                customTextStyle={styles.footerButtonText}
                onPress={handleChangePassword}>
                {i18n.t('account_settings.change')}
              </Button>
            ) : null}
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default ChangePassword;
