import { StyleSheet } from 'react-native';

import { WINDOW_WIDTH } from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

const HALF = WINDOW_WIDTH / 2;
const BUTTON_WIDTH = HALF - Spacing.LATERAL - 5.5;

export default StyleSheet.create({
  button: {
    backgroundColor: Colors.buttonPrimActive,
    borderRadius: 10,
    paddingVertical: moderateScale(10),
    paddingHorizontal: moderateScale(20),
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  disabled: {
    backgroundColor: Colors.limeOpacity(0.65),
    opacity: 0.3,
  },
  disabledSecondary: {
    opacity: 0.5,
  },
  home: {
    width: BUTTON_WIDTH,
    paddingVertical: 11,
  },
  fixedBottom: {
    position: 'absolute',
    bottom: 0,
  },
  secondary: {
    backgroundColor: Colors.blackTwo,
  },
  terciary: {
    backgroundColor: Colors.shareAppBackground,
  },
  disabledTerciary: {
    opacity: 1,
  },
});
