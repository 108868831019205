import React from 'react';
import { Linking, View } from 'react-native';

import { isTablet } from '@constants/platform';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import RoundedButton from '@atoms/RoundedButton';
import { Colors } from '@theme';
import ChevronLeft from '@assets/svg/ChevronLeft';
import links from '@constants/links';

import styles, { responsiveFontSize } from './styles';

export const GatoBlogBanner = (): JSX.Element => {
  // hooks
  const i18n = useTranslations();

  // render
  return (
    <View style={styles.main}>
      <View>
        <CustomText
          numberOfLines={isTablet ? 1 : 2}
          align="left"
          weight="interBlack"
          size={isTablet ? 'big' : responsiveFontSize[0]}
          color="white">
          {i18n.t('home.blog')}
        </CustomText>

        <CustomText
          align="left"
          weight="interRegular"
          size={isTablet ? 'medium' : responsiveFontSize[1]}
          color="textInputDefault">
          {i18n.t('home.portal_reference')}
        </CustomText>
      </View>
      <RoundedButton customStyle={styles.roundedButton} action={() => Linking.openURL(links.gatoBlog)}>
        <ChevronLeft fill={Colors.blackTwo} style={styles.arrow} />
      </RoundedButton>
    </View>
  );
};
