import React from 'react';
import { Image, View, useWindowDimensions } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import BG from '@assets/img/error/background.png';
import TextureBG from '@assets/img/error/texture.png';
import ServerCAT from '@assets/img/error/CAT.png';
import IllustrationPNG from '@assets/img/error/illustration.png';
import { isTablet, isWebResponsive } from '@constants/platform';

import { styles } from './styles';

export const ErrorFallback = () => {
  // connstants
  const { height, width } = useWindowDimensions();

  // hooks
  const i18n = useTranslations();
  // functions

  // render
  return (
    <View style={styles.container}>
      <Image
        source={BG}
        style={[
          {
            width,
            height,
          },
          styles.background,
        ]}
      />
      <Image
        source={TextureBG}
        style={[
          {
            width,
            height,
          },
          styles.backgroundTexture,
        ]}
      />
      {isWebResponsive ? (
        <Image source={IllustrationPNG} style={styles.illustration} />
      ) : (
        <Image source={ServerCAT} style={styles.serverPNG} />
      )}
      <CustomText
        size={isWebResponsive ? 'xbig' : 'mlarge'}
        color="limeGreen"
        numberOfLines={2}
        customStyle={styles.title}
        align="center">
        {i18n.t('error.error_title_1')} {isWebResponsive || isTablet ? <br /> : ' '}
        {i18n.t('error.error_title_2')}
      </CustomText>
      <CustomText customStyle={styles.subtitle} align="center" weight="interRegular" size="big">
        {i18n.t('error.error_description')}
      </CustomText>
    </View>
  );
};
