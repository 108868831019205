import { isWebResponsive } from '@constants/platform';

/**
 * When the app is responsive, the carrousel names are different between mobile and desktop,
 * so we need to map them. Only the carrousel names 'best_for_mobile' and 'to_be_discovered' are different for now.
 * @param translation key of the translation
 * @returns mapped key of the translation
 */
const mapCarrouselNameTranslations = (translation: string) => {
  if (!isWebResponsive) return translation;

  if (translation === 'carousel.best_for_mobile') return 'carousel.best_for_desktop';
  if (translation === 'carousel.to_be_discovered') return 'carousel.best_for_mobile';

  return translation;
};

export { mapCarrouselNameTranslations };
