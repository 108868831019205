import React from 'react';

import FacebookButtonIcon from '@assets/svg/buttonIcons/FacebookButtonIcon';
import GoogleButtonIcon from '@assets/svg/buttonIcons/GoogleButtonIcon';
import AppleButtonIcon from '@assets/svg/buttonIcons/AppleButtonIcon';
import Button from '@atoms/Button';
import useHistoryRoutePath, { lastHistoryPathSelector } from '@store/historyRoutePath';
import { isWebResponsive } from '@constants/platform';
import { getEnv } from '@config/env';

import styles from './styles';
import useAppleId from './hooks/useAppleId';

const BASE_URL_AUTH = getEnv('baseUrlAuth');
interface SocialButtonProps {
  provider: 'google' | 'facebook' | 'apple';
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
}

const SocialButton = ({ provider, disabled, setDisabled }: SocialButtonProps) => {
  // Hooks
  const { handleAppleId } = useAppleId();
  const lastPath = useHistoryRoutePath(lastHistoryPathSelector);

  // Functions
  const googleLoginHandler = () => {
    const previousRoute = lastPath;

    window.location.href = `${BASE_URL_AUTH}/auth/google?returnTo=${previousRoute}`;
    setDisabled(true);
  };

  const facebookLoginHandler = () => {
    const previousRoute = lastPath;
    window.location.href = `${BASE_URL_AUTH}/auth/facebook?returnTo=${previousRoute}`;
    setDisabled(true);
  };

  let onPressHandler = googleLoginHandler;
  let Icon = GoogleButtonIcon;
  let text = 'Google';

  if (provider === 'google') {
    onPressHandler = googleLoginHandler;
    Icon = GoogleButtonIcon;
    text = 'Google';
  } else if (provider === 'facebook') {
    onPressHandler = facebookLoginHandler;
    Icon = FacebookButtonIcon;
    text = 'Facebook';
  } else if (provider === 'apple') {
    onPressHandler = handleAppleId;
    Icon = AppleButtonIcon;
    text = 'Apple';
  }

  return (
    <Button
      secondary
      customStyle={styles.socialLoginButton}
      customTextStyle={styles.socialLoginButtonText}
      onPress={onPressHandler}
      iconPosition="left"
      icon={<Icon />}
      disabled={disabled}>
      {isWebResponsive ? '' : text}
    </Button>
  );
};

export default SocialButton;
