import React, { useEffect } from 'react';

import { setAuthTokenHandler, useStore } from '@store/index';
import { setAuthToken, setRefreshToken } from '@utils/session';
import toast from '@utils/toast';

import { useTranslations } from './useTranslation';

// In a future, can be more generic
const useHashParams = () => {
  const { t } = useTranslations();
  const setStoreToken = useStore(setAuthTokenHandler);

  // Functions
  const getHashParam = (queryKey: string): string | undefined => {
    const hashParams = window.location.hash.replace('#', '')?.split('&');

    return hashParams?.find((param) => param.startsWith(queryKey))?.split('=')[1];
  };

  const clearUrlHash = () => {
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
  };

  // Effects
  useEffect(() => {
    const error = getHashParam('error');

    if (error) {
      toast.danger({
        title: t('login.user_have_other_signin_method'),
        duration: 9000,
        icon: 'error',
      });
      clearUrlHash();
      return;
    }

    const accessToken = getHashParam('access_token');
    const refreshToken = getHashParam('refresh_token');
    clearUrlHash();

    if (accessToken && refreshToken) {
      setAuthToken(accessToken);
      setStoreToken(accessToken);
      setRefreshToken(refreshToken);
    }
  }, []);
};

export default useHashParams;
