import React from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

export const BalanceIcon = (props: SvgProps): JSX.Element => {
  // constants
  const { fill = '#777E91' } = props;
  // states

  // hooks

  // functions

  // effects

  // render

  return (
    <Svg width="16" height="20" viewBox="0 0 16 20" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6023 3.25H4.39826C3.23581 1.67982 4.80193 -0.517727 6.74734 0.109848L7.67511 0.409141C7.88634 0.477283 8.11419 0.477283 8.32543 0.409141L9.2532 0.109848C11.1986 -0.517727 12.7647 1.67982 11.6023 3.25ZM4.50643 4.75H11.4941C12.8134 5.48048 13.8441 6.70013 14.316 8.21035L15.566 12.2104C16.7734 16.0739 13.887 20 9.83912 20H6.16141C2.11357 20 -0.77284 16.0739 0.43453 12.2104L1.68453 8.21035C2.15648 6.70013 3.18715 5.48048 4.50643 4.75ZM8.75024 7.5C8.75024 7.08579 8.41446 6.75 8.00024 6.75C7.58603 6.75 7.25024 7.08579 7.25024 7.5V8.34618C6.14423 8.6338 5.25024 9.54274 5.25024 10.75C5.25024 12.2217 6.57867 13.25 8.00024 13.25C8.7878 13.25 9.25024 13.7887 9.25024 14.25C9.25024 14.7113 8.7878 15.25 8.00024 15.25C7.21268 15.25 6.75024 14.7113 6.75024 14.25C6.75024 13.8358 6.41446 13.5 6.00024 13.5C5.58603 13.5 5.25024 13.8358 5.25024 14.25C5.25024 15.4573 6.14423 16.3662 7.25024 16.6538V17.5C7.25024 17.9142 7.58603 18.25 8.00024 18.25C8.41446 18.25 8.75024 17.9142 8.75024 17.5V16.6538C9.85626 16.3662 10.7502 15.4573 10.7502 14.25C10.7502 12.7783 9.42182 11.75 8.00024 11.75C7.21268 11.75 6.75024 11.2113 6.75024 10.75C6.75024 10.2887 7.21268 9.75 8.00024 9.75C8.7878 9.75 9.25024 10.2887 9.25024 10.75C9.25024 11.1642 9.58603 11.5 10.0002 11.5C10.4145 11.5 10.7502 11.1642 10.7502 10.75C10.7502 9.54274 9.85626 8.6338 8.75024 8.34618V7.5Z"
        fill={fill}
      />
    </Svg>
  );
};
