import * as React from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

const DownArrow = (props: SvgProps) => (
  <Svg width={24} height={25} fill="none" {...props}>
    <Path
      fill="#fff"
      fillRule="evenodd"
      d="M16.207 10.293a1 1 0 0 0-1.414 0L12 13.086l-2.793-2.793a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default DownArrow;
