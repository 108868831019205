import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { IS_SMALL_DEVICE, isTablet, isWebResponsive, SCREEN_HEIGHT } from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { BIG, LARGE } from 'app/theme/spacing';

// eslint-disable-next-line no-nested-ternary
const widthSize = isWebResponsive ? moderateScale(300) : isTablet ? moderateScale(464) : moderateScale(526);

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: IS_SMALL_DEVICE ? SCREEN_HEIGHT : SCREEN_HEIGHT - moderateScale(120),
  },
  // container: {
  //   flex: 1,
  //   height: SCREEN_HEIGHT - moderateScale(120),
  //   paddingVertical: '32px',
  //   paddingHorizontal: Spacing.LATERALPLUS,
  // },
  description: {
    marginTop: moderateScale(16),
    marginBottom: moderateScale(30),
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  desktopForm: {
    flex: 2,
    width: widthSize,
    maxHeight: moderateVerticalScale(721),
    marginBottom: LARGE,
  },
  button: {
    width: '100%',
    minHeight: moderateScale(46),
    paddingVertical: moderateScale(14),
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: moderateScale(18),
    marginTop: isWebResponsive ? moderateScale(70) : moderateScale(140),
  },
  underline: {
    textDecorationLine: 'underline',
  },
  checkContainer: {
    width: '90%',
    marginBottom: moderateScale(24),
  },
  checkPress: {
    marginTop: -2,
  },
  socialLogin: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: moderateScale(8),
  },
  socialLoginDivider: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: moderateScale(20),
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    borderBottomStyle: 'solid',
  },
  contentContainer: {
    padding: moderateScale(20),
    backgroundColor: Colors.blackTwo,
    paddingTop: moderateScale(10),
  },
  header: {
    backgroundColor: Colors.blackTwo,
    paddingTop: moderateScale(20),
    paddingHorizontal: moderateScale(20),
    paddingBottom: moderateScale(10),
  },
  border: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    paddingBottom: moderateScale(16),
  },
  modalFooter: {
    paddingTop: moderateScale(16),
    paddingHorizontal: moderateScale(24),
    paddingBottom: moderateScale(20),
    alignItems: 'center',
    backgroundColor: Colors.blackTwo,
  },
  modalButton: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
  },
  termsText: {
    lineHeight: moderateScale(24),
  },
  bottomSheet: {
    minHeight: 300,
  },
  logoImage: {
    height: 42,
    width: 125,
    marginBottom: BIG,
    alignSelf: 'center',
  },
  input: {
    backgroundColor: Colors.darkGrey,
    padding: moderateScale(16),
    borderRadius: 16,
    height: moderateScale(48),
    maxWidth: widthSize,
  },
});
