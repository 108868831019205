import { registerRootComponent } from 'expo';
// import messaging from '@react-native-firebase/messaging';
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
// import { localNotificationService } from '@config/LocalNotificationService';
import { Platform } from 'react-native';
import { createRoot } from 'react-dom/client';
import { createElement } from 'react';

import App from './App';
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

// Storybook

// import StorybookUIRoot from './storybook';
// registerRootComponent(StorybookUIRoot);

// messaging().setBackgroundMessageHandler(async (remoteMessage) => {
//   console.log('remoteMessage background', remoteMessage);
//   if (remoteMessage) {
//     const notify = remoteMessage.data;
//     localNotificationService.showNotification(notify.title, notify.body, notify);
//   }
// });

// registerRootComponent(App);

if (Platform.OS === 'web') {
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  rootTag.render(createElement(App));
} else {
  registerRootComponent(App);
}
enableExperimentalWebImplementation(true);
