import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { isMobile, isWebResponsive, SCREEN_HEIGHT } from '@constants/platform';
import { LARGE, SMALL, XLARGE } from 'app/theme/spacing';

export const styles = StyleSheet.create({
  container: {
    marginTop: isWebResponsive ? moderateScale(36) : undefined,
    flex: 1,
    minHeight: isMobile || isWebResponsive ? SCREEN_HEIGHT - moderateScale(156) : undefined,
  },
  desktopForm: {
    width: isWebResponsive ? '100%' : moderateScale(470),
    marginTop: isWebResponsive ? moderateScale(24) : moderateScale(40) + SMALL,
  },
  body: {},
  description: {
    marginTop: isMobile || isWebResponsive ? SMALL : LARGE,
    marginBottom: isMobile || isWebResponsive ? moderateScale(30) : XLARGE,
  },
  footer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },

  underline: {
    textDecorationLine: 'underline',
  },
  button: {
    width: '100%',
    paddingVertical: moderateScale(14),
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
});
