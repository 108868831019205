export const videoOptions = {
  autoplay: 'play',
  controls: false,
  sources: [
    {
      src: 'https://gato-files-prod.s3.amazonaws.com/ads-images/placeholder-video-ad.mp4',
      type: 'video/mp4',
    },
  ],
};

export const imaOptions = {
  adTagUrl: '',
  autoPlayAdBreaks: false,
  showCountdown: true,
  contribAdsSettings: {
    allowVjsAutoplay: true,
  },
  disableAdControls: true,
  disableCustomPlaybackForIOS10Plus: true,
};

export const AD_ERROR_EVENT_TYPE = 'adError';
