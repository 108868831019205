/* eslint-disable no-param-reassign */
import axios from 'axios';

import { getAuthToken } from '@utils/session';

import { getEnv } from './env';

const baseURLGames = getEnv('baseUrlGames');
const baseURLAuth = getEnv('baseUrlAuth');
const baseURLNotifications = getEnv('baseUrlNotifications');
const baseURLSearchLambda = getEnv('baseUrlSearchLambda');

export const isDev = getEnv('environment') !== 'prod';

export const axiosGamesInstance = axios.create({
  baseURL: baseURLGames,
});

export const axiosAuthInstance = axios.create({
  baseURL: baseURLAuth,
  withCredentials: true,
});

export const axiosNotificationsInstance = axios.create({
  baseURL: baseURLNotifications,
});

export const axiosSearchInstance = axios.create({
  baseURL: baseURLSearchLambda,
});

axiosGamesInstance.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    if (token !== undefined && token !== null) {
      if (config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (err) => Promise.reject(err)
);

const REJECTED_URLS = ['/auth', '/users/verify/mail', '/users/verify/username'];

const checkIsRejected = (url: string) => REJECTED_URLS.find((resource) => url.includes(resource));

axiosAuthInstance.interceptors.request.use(
  async (config) => {
    const isRejected = checkIsRejected(config?.url || '');

    if (!isRejected) {
      const token = await getAuthToken();
      if (token && config && config.headers) config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

axiosNotificationsInstance.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    if (token !== undefined && token !== null) {
      if (config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (err) => Promise.reject(err)
);
