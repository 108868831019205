import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors, Spacing } from '@theme';
import { LATERALBIG } from 'app/theme/spacing';

export default StyleSheet.create({
  mainContainer: {
    zIndex: 99,
    position: 'absolute',
    width: '100%',
    bottom: moderateScale(50),
    left: moderateScale(60),
    paddingVertical: moderateScale(10),
  },
  mainContainerResponsive: {
    zIndex: 99,
    position: 'absolute',
    width: '100%',
    left: 0,
    paddingVertical: moderateScale(10),
  },
  downArrow: {
    backgroundColor: Colors.buttonTerciary,
    borderRadius: 100,
    width: moderateScale(24),
    height: moderateScale(24),
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: { paddingLeft: Spacing.LATERALBIG },
  list: {
    paddingLeft: Spacing.LATERALBIG,
    marginTop: moderateScale(10),
    alignItems: 'center',
  },
  itemWrapper: {
    marginRight: LATERALBIG,
  },
});
