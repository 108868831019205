import React, { useEffect, useState } from 'react';
import { Image, LayoutChangeEvent, View, ViewProps, Pressable, Linking } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { useTranslation } from 'react-i18next';
import { LinearGradient } from 'expo-linear-gradient';

import { isWebResponsive } from '@constants/platform';
import CustomText from '@atoms/CustomText';
import SparkIcon from '@assets/svg/SparkIcon';
import useFeatureFlag, { christmasEventSelector, gameQuestEventSelector } from '@store/featureFlag';
import StarSecond from '@assets/svg/StartGGQ2';
import ChristmasStarPNG from '@assets/img/ChristmasStar.png';
import GGQAwards from '@assets/img/GGQ_Awards.png';
import GameQuest from '@assets/img/GGQ2Logo.png';
import links from '@constants/links';

import { styles } from './styles';

interface MarqueeProps {
  speed?: number;
}

const MarqueeContent = (props: ViewProps): JSX.Element => {
  // constants
  const textSize = isWebResponsive ? 'msmall' : 'lmedium';
  const textWeight = isWebResponsive ? 'interBlack' : 'interBold';

  // hooks
  const i18n = useTranslation();

  // store
  const isGGQ = useFeatureFlag(gameQuestEventSelector);
  const isChristmas = useFeatureFlag(christmasEventSelector);

  // render
  const ggq2 = true;
  if (ggq2) {
    return (
      <View style={styles.textWrapper} {...props}>
        <StarSecond style={styles.spark} />
        <Image resizeMode="cover" style={styles.gameQuest} source={GameQuest} />
        <StarSecond style={styles.spark} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.play_and_vote')}
        </CustomText>
        <StarSecond style={styles.spark} />
        <Image resizeMode="cover" style={styles.gameQuest} source={GameQuest} />
        <StarSecond style={styles.spark} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.upload_your_game')}
        </CustomText>
      </View>
    );
  }

  if (isGGQ) {
    return (
      <View style={styles.textWrapper} {...props}>
        <StarSecond style={styles.spark} />
        <Image resizeMode="cover" style={styles.gameQuest} source={GameQuest} />
        <StarSecond style={styles.spark} />
        <Image resizeMode="cover" style={styles.awards} source={GGQAwards} />
        <StarSecond style={styles.spark} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.meet_ggq_winners')}
        </CustomText>
      </View>
    );
  }

  if (isChristmas && !isGGQ) {
    return (
      <View style={styles.textWrapper} {...props}>
        <Image resizeMode="cover" style={[styles.christmasStar, styles.spark]} source={ChristmasStarPNG} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.christmas.avatar_statement')}
        </CustomText>
        <Image resizeMode="cover" style={[styles.christmasStar, styles.spark]} source={ChristmasStarPNG} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.christmas.santa_claus')}
        </CustomText>
        <Image resizeMode="cover" style={[styles.christmasStar, styles.spark]} source={ChristmasStarPNG} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.christmas.merry_christmas')}
        </CustomText>
      </View>
    );
  }

  return (
    <View style={styles.textWrapper} {...props}>
      <SparkIcon style={styles.spark} />
      <CustomText size={textSize} weight={textWeight} color="white">
        {i18n.t('marquee.free')}
      </CustomText>
      <SparkIcon style={styles.spark} />
      <CustomText size={textSize} weight={textWeight} color="white">
        {i18n.t('marquee.social')}
      </CustomText>
      <SparkIcon style={styles.spark} />
      <CustomText size={textSize} weight={textWeight} color="white">
        {i18n.t('marquee.awesome')}
      </CustomText>
    </View>
  );
};

const Marquee = ({ speed = 0.1 }: MarqueeProps): JSX.Element => {
  // states
  const [titleWidth, setTitleWidth] = useState<number>();

  // hooks
  const transformValue = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: transformValue.value,
      },
    ],
  }));

  // functions
  function handleLayout(event: LayoutChangeEvent): void {
    setTitleWidth(event.nativeEvent.layout.width);
  }
  // effects
  useEffect(() => {
    if (!titleWidth) return;
    transformValue.value = 0;
    transformValue.value = withRepeat(
      withTiming(-titleWidth, {
        duration: titleWidth / speed,
        easing: Easing.linear,
      }),
      -1,
      false
    );
  }, [titleWidth]);

  // render
  return (
    <Pressable onPress={() => Linking.openURL(links.gameQuestLink)}>
      <View style={{ width: '100%', position: 'relative' }}>
        <LinearGradient
          colors={['#141416', '#A0536A', '#A0536A', '#141416']}
          start={{ x: 0.01, y: 35.57 }}
          end={{ x: 68.53, y: 100.43 }}
          style={{ position: 'absolute', width: '100%', height: '100%', top: 0 }}
        />
        <Animated.View style={[styles.container, animatedStyles]}>
          <MarqueeContent onLayout={handleLayout} />
          <MarqueeContent />
          <MarqueeContent />
          <MarqueeContent />
          <MarqueeContent />
          <MarqueeContent />
        </Animated.View>
      </View>
    </Pressable>
  );
};

export default Marquee;

export const GGQNewDeadlineChip = ({ title }: { title: string }) => (
  <View style={styles.chip}>
    <LinearGradient
      colors={['#D64D70', '#DB264D']}
      start={{ x: 0, y: 35.57 }}
      end={{ x: 1, y: 100.43 }}
      style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 0 }}
    />
    <CustomText size="lmedium" weight="interSemi" customStyle={{ zIndex: 99 }}>
      {title}
    </CustomText>
  </View>
);
