import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import DeviceWebDesktop from '@assets/svg/DeviceWebDesktop';
import DeviceWebMobile from '@assets/svg/DeviceWebMobile';
import DeviceIOS from '@assets/svg/DeviceIOS';
import DeviceAndroid from '@assets/svg/DeviceAndroid';
import Progressbar from '@molecules/Progressbar';
import { Colors } from '@theme';
import { useDashboardCreator } from '@services/useDashboardCreator';
import { useStore, authTokenSelector } from '@store/index';
import { useUserData } from '@services/useUserData';
import { useGetGamesByCreator } from '@services/useGetGamesByCreator';

import {
  allMonthsArray,
  CalendarType,
  ControllerFields,
  filtersDefaultValues,
  GraphicType,
  graphicTypesArray,
  IFilters,
  yearsArray,
} from '../helper';

import { SelectTheme, customSelectStyles, styles } from './styles';

const CustomTooltip = ({
  active,
  payload,
  label,
  graphicType,
  sessionText,
}: {
  active?: boolean;
  payload?: any;
  label?: any;
  graphicType: string | null;
  sessionText: string;
}): JSX.Element => {
  if (active && payload && payload.length) {
    const date = new Date(label);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      timeZone: 'UTC',
    };
    const spanishDate = date.toLocaleDateString('es-ES', options);

    return (
      <View style={styles.tooltip}>
        <CustomText
          color="greyEight"
          align="left"
          weight="interSemi"
          size="msmall"
          customStyle={styles.tooltipDate}>
          {spanishDate}
        </CustomText>
        <View style={styles.inlineToolTip}>
          <View style={styles.tooltipSquare} />
          <CustomText color="white" align="left" weight="interSemi" size="msmall">
            {Math.round(payload[0].value * 100) / 100}{' '}
            {graphicType?.toLowerCase().includes('ti') ? 'mins' : sessionText}
          </CustomText>
        </View>
      </View>
    );
  }
  return <View />;
};

function formatXAxis(tickItem: string): string {
  return tickItem.slice(-2);
}

export const AnalyticsScreen = (): JSX.Element => {
  // main dependencies boilerplate
  const currentDate: Date = new Date();
  const i18n = useTranslations();
  const authToken = useStore(authTokenSelector);
  const userData = useUserData(authToken || '');
  const [yearSelected, setYearSelected] = useState<string | null>(currentDate.getFullYear().toString());

  // constants
  const monthActualValue: string = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const translatedMonths: CalendarType[] = allMonthsArray.map((m) => {
    const all: typeof m = {
      label: i18n.t(m.label),
      value: m.value,
    };
    return all;
  });
  const translatedGraphics: GraphicType[] = graphicTypesArray.map((g) => {
    const all: typeof g = {
      label: i18n.t(g.label),
      value: g.value,
    };
    return all;
  });
  // if we are in current year, we filter the months hasn't lapse yet, else, we show the entire month list
  const MONTHS: CalendarType[] =
    currentDate.getFullYear() === Number(yearSelected!)
      ? translatedMonths.filter((item) => item.value <= monthActualValue)
      : translatedMonths;

  const monthActualLabel: CalendarType | undefined = MONTHS.find((month) => month.value === monthActualValue);
  const creatorId: number | undefined = userData?.data?.id;

  // states
  const [games, setGames] = useState<any[] | undefined>(undefined);
  const [gameSelected, setGameSelected] = useState<string>('');
  const [monthSelected, setMonthSelected] = useState<string | null>(
    i18n.t(monthActualLabel?.label || '') || ''
  );
  const [graphicType, setGraphicType] = useState<string | null>(
    i18n.t('dashboard.graphic.number_of_sessions')
  );
  const [filters, setFilters] = useState<IFilters>(filtersDefaultValues);

  // hooks
  const { control } = useForm();
  const { data: creator } = useGetGamesByCreator(Number(creatorId), 'CREATOR');
  const {
    isLoading,
    isError,
    data,
    refetch: refetchDashboard,
    isRefetching,
  } = useDashboardCreator(
    filters.creatorId,
    filters.allGames,
    filters.year,
    filters.gameId,
    filters.month,
    filters.numberOfSessions,
    filters.sessionTime,
    filters.sessionAverage
  );

  const totalMinutes: number = data?.performance_graph?.total || 0;
  // functions
  function updateState(key: string, value: string | boolean): void {
    setFilters({
      ...filters,
      [key]: value,
    });
  }

  function getGraphicType(type: string): void {
    setFilters((prev) => ({
      ...prev,
      numberOfSessions: type === 'numberOfSessions',
      sessionTime: type === 'sessionTime',
      sessionAverage: type === 'sessionAverage',
    }));
  }

  // effects
  useEffect(() => {
    if (creatorId) {
      updateState('creatorId', String(creatorId));
    }
  }, [creatorId]);

  useEffect(() => {
    if (creator) {
      const creatorGames = creator[0]?.games.map((game: any) => ({
        value: game.id,
        label: game.name,
      }));
      if (Array.isArray(creatorGames)) {
        creatorGames.unshift({
          value: '',
          label: i18n.t('dashboard.graphic.all_games'),
        });

        setGames(creatorGames);
      }
    }
  }, [creator]);

  useEffect(() => {
    refetchDashboard();
  }, [filters]);

  // render
  return (
    <ScrollView style={styles.container}>
      {isError ? (
        <View style={styles.loading}>
          <CustomText weight="interSemi" align="center" size="mlarge">
            Ha ocurrido un error
          </CustomText>
        </View>
      ) : null}

      {isLoading || isRefetching || isError || !data || !creatorId ? (
        <View style={styles.loading}>
          <ActivityIndicator size={72} color={Colors.limeGreen} />
        </View>
      ) : (
        <>
          <View style={styles.header}>
            <CustomText weight="interSemi" align="left" size="mlarge">
              {i18n.t('dashboard.sidebar.analytics')}
            </CustomText>
            <View style={styles.inlineToolTip}>
              <Controller
                name={ControllerFields.PLATFORM}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    options={games}
                    placeholder={gameSelected || i18n.t('dashboard.graphic.all_games')}
                    styles={customSelectStyles}
                    theme={SelectTheme}
                    onChange={(e: SingleValue<any>) => {
                      setGameSelected(e?.label);
                      field.onChange(e?.value);
                      updateState('gameId', String(e?.value));
                    }}
                  />
                )}
              />
              <View style={styles.select}>
                <Controller
                  name={ControllerFields.PLATFORM}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      options={MONTHS}
                      placeholder={monthSelected || 'Mes'}
                      styles={customSelectStyles}
                      theme={SelectTheme}
                      onChange={(e) => {
                        setMonthSelected(e?.label || '');
                        field.onChange(e?.value);
                        updateState('month', String(e?.value));
                      }}
                    />
                  )}
                />
              </View>

              <View style={styles.select}>
                <Controller
                  name={ControllerFields.PLATFORM}
                  control={control}
                  defaultValue={yearSelected}
                  render={({ field }) => (
                    <Select
                      options={yearsArray}
                      placeholder={yearSelected}
                      styles={customSelectStyles}
                      theme={SelectTheme}
                      onChange={(e) => {
                        setYearSelected(e?.label || '');
                        field.onChange(e?.value);
                        updateState('year', String(e?.value));
                      }}
                    />
                  )}
                />
              </View>
            </View>
          </View>
          <View style={styles.graphic}>
            <View style={styles.inline}>
              <View>
                <CustomText weight="interSemi" align="left" size="mbig">
                  {i18n.t('dashboard.graphic.in_total')}
                </CustomText>
                <CustomText weight="interSemi" align="left" size="xlarge">
                  {Math.round(totalMinutes * 100) / 100}{' '}
                  {graphicType === i18n.t('dashboard.graphic.number_of_sessions')
                    ? i18n.t('dashboard.graphic.sessions')
                    : 'mins'}
                </CustomText>
              </View>

              <View style={styles.select}>
                <Controller
                  name={ControllerFields.PLATFORM}
                  control={control}
                  defaultValue={i18n.t('dashboard.graphic.number_of_sessions')}
                  render={({ field }) => (
                    <Select
                      options={translatedGraphics}
                      placeholder={
                        graphicType ? i18n.t(graphicType) : i18n.t('dashboard.graphic.number_of_sessions')
                      }
                      styles={customSelectStyles}
                      theme={SelectTheme}
                      onChange={(e) => {
                        field.onChange(e?.value);
                        setGraphicType(e?.label || '');
                        getGraphicType(e?.value || '');
                      }}
                    />
                  )}
                />
              </View>
            </View>

            <View style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <LineChart
                  width={860}
                  height={300}
                  data={data?.performance_graph?.month}
                  margin={{ top: 60, bottom: 5, left: 0, right: 0 }}
                  {...{
                    overflow: 'visible',
                  }}>
                  <CartesianGrid stroke="#9A9FA550" strokeDasharray="2 2" />
                  <XAxis
                    includeHidden
                    dataKey="name"
                    tickFormatter={formatXAxis}
                    interval={0}
                    // tick={{ fontSize: moderateScale(12) }}
                  />
                  <YAxis />
                  <Tooltip
                    content={
                      <CustomTooltip
                        graphicType={graphicType}
                        sessionText={i18n.t('dashboard.graphic.sessions')}
                      />
                    }
                  />
                  <Line type="monotone" dataKey="value" stroke="#E5F68C" />
                </LineChart>
              </ResponsiveContainer>
            </View>
          </View>

          <View style={styles.graphics}>
            <View style={styles.graphic}>
              <CustomText weight="interSemi" align="left" size="mbig">
                {i18n.t('dashboard.graphic.session_by')}
              </CustomText>
              <CustomText weight="interSemi" align="left" size="xlarge">
                {i18n.t('dashboard.graphic.device')}
              </CustomText>

              <View>
                <View style={[styles.inline, styles.devicesContainer]}>
                  <View style={styles.inline}>
                    <DeviceWebDesktop />
                    <CustomText weight="interSemi" align="left" size="big" customStyle={styles.device}>
                      {i18n.t('dashboard.graphic.web_desktop')}
                    </CustomText>
                  </View>
                  <CustomText weight="interSemi" align="right" size="big">
                    {Math.round(Number(data?.sessions_device_graph?.WEB_DESKTOP) * 100)}%
                  </CustomText>
                </View>

                <Progressbar
                  porcetange={Math.round(Number(data?.sessions_device_graph?.WEB_DESKTOP) * 100)}
                />
              </View>

              <View>
                <View style={[styles.inline, styles.devicesContainer]}>
                  <View style={styles.inline}>
                    <DeviceWebMobile />
                    <CustomText weight="interSemi" align="left" size="big" customStyle={styles.device}>
                      {i18n.t('dashboard.graphic.web_mobile')}
                    </CustomText>
                  </View>
                  <CustomText weight="interSemi" align="right" size="big">
                    {Math.round(Number(data?.sessions_device_graph?.WEB_MOBILE) * 100)}%
                  </CustomText>
                </View>

                <Progressbar porcetange={Math.round(Number(data?.sessions_device_graph?.WEB_MOBILE) * 100)} />
              </View>

              <View>
                <View style={[styles.inline, styles.devicesContainer]}>
                  <View style={styles.inline}>
                    <DeviceIOS />
                    <CustomText weight="interSemi" align="left" size="big" customStyle={styles.device}>
                      {i18n.t('dashboard.graphic.app_ios')}
                    </CustomText>
                  </View>
                  <CustomText weight="interSemi" align="right" size="big">
                    {Math.round(Number(data?.sessions_device_graph?.IOS) * 100)}%
                  </CustomText>
                </View>

                <Progressbar porcetange={Math.round(Number(data?.sessions_device_graph?.IOS) * 100)} />
              </View>

              <View>
                <View style={[styles.inline, styles.devicesContainer]}>
                  <View style={styles.inline}>
                    <DeviceAndroid />
                    <CustomText weight="interSemi" align="left" size="big" customStyle={styles.device}>
                      {i18n.t('dashboard.graphic.app_android')}
                    </CustomText>
                  </View>
                  <CustomText weight="interSemi" align="right" size="big">
                    {Math.round(Number(data?.sessions_device_graph?.ANDROID) * 100)}%
                  </CustomText>
                </View>
              </View>

              <Progressbar porcetange={Math.round(Number(data?.sessions_device_graph?.ANDROID) * 100)} />
            </View>
          </View>
        </>
      )}
    </ScrollView>
  );
};
