import { CommonActions, useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useTranslations } from '@hooks/useTranslation';
import { setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { RootStackNavigator } from 'app/navigation/types';
import { useCountryIsoCode } from '@hooks/useCountryIsoCode';
import { useGameDetail } from '@services/useGameDetail';
import { Game } from '@interfaces/gameDetail';

export default function useLeaveGameModal(beforeRedirect: () => void, name: string | undefined) {
  const i18n = useTranslations();
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();
  const countryISOCode = useCountryIsoCode();
  const { data: gameDetail } = useGameDetail(name || '', countryISOCode);
  const postPlayGames: Game[] | undefined = gameDetail?.gameDto.gamesMightAlsoLike;
  const confirmLeaveGame = () => {
    toggleModal();
    beforeRedirect();
    navigation.setOptions({ headerShown: true });

    // If the user enter to the game from the game page, we need to go back to the game page with History API of the browser because the game page is not in the navigation stack
    if (!navigation.canGoBack() && window.history.length > 2) {
      window.history.back();
    }

    // If the user enter directly to the game, we need to reset the navigation
    if (!navigation.canGoBack() && window.history.length <= 2) {
      return navigation.reset({
        index: 0,
        routes: [{ name: 'Home' }],
      });
    }

    return navigation.dispatch(CommonActions.goBack());
  };

  const cancelLeaveGame = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const handleLeaveGame = () => {
    setModalInfo({
      message: i18n.t('game_web_view.leave_message').toString(),
      confirmText: i18n.t('game_web_view.leave_confirm_text').toString(),
      cancelText: i18n.t('game_web_view.leave_cancel_text').toString(),
      confirmAction: confirmLeaveGame,
      cancelAction: cancelLeaveGame,
      postPlayGames,
    });
    toggleModal();
  };

  // add post play recommendation games

  return handleLeaveGame;
}
