/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { QueryKeys } from 'app/react-query/query-keys';
import { axiosGamesInstance } from '@config/api';
import { isIos, isMobile } from '@constants/platform';
import { Carousels } from '@interfaces/home';

import pkg from '../../package.json';

async function getHomeCarousels(param: boolean, location: string, isLoggedIn: boolean) {
  const SO = isIos ? 'IOS' : 'ANDROID';
  const path = isMobile ? `/carrousel/games?location=${location}` : `/carrousel/games/desktop?isMobile=${param}&location=${location}`;
  const config = isMobile ? { headers: { so_name: SO, so_version: pkg.version } } : undefined;
  const response = await axiosGamesInstance.get<Carousels>(path, config);

  if (!response || !response.data) return;

  const carousels = response.data.carrosuels;

  const carouselsOrder: string[] = isLoggedIn
    ? [
        'principal',
        'continuePlaying',
        'gatosTopPicks',
        'mostPlayedByYou',
        'latestReleases',
        'friendsPlaying',
        'mostPlayed',
        'gamesForYou',
        'bestForDesktop',
        'bestForMobile',
        'toBeDiscovered',
      ]
    : [
        'principal',
        'mostPlayed',
        'continuePlaying',
        'gatosTopPicks',
        'mostPlayedByYou',
        'latestReleases',
        'friendsPlaying',
        'gamesForYou',
        'bestForDesktop',
        'bestForMobile',
        'toBeDiscovered',
      ];

  const ordered = [];
  carouselsOrder.forEach((key) => {
    const item = carousels.find(i => i.name === key);

    if (!item) return;

    ordered.push(item);
  });

  response.data.carrosuels = ordered;
  return response.data;
}

export function useHomeCarousels(param: boolean, location: string, isLoggedIn: boolean) {
  return useQuery([QueryKeys.GET_HOME_CAROUSELS, param], () => getHomeCarousels(param, location, isLoggedIn), {
    enabled: !!location
  });
}
