import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

export default StyleSheet.create({
  body: {
    justifyContent: 'flex-end',
    flex: 1,
    backgroundColor: Colors.blackThree,
    paddingVertical: '10%',
  },
  background: {
    resizeMode: 'cover',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  backgroundSTARS: {
    resizeMode: 'repeat',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  PNGWrapper: { width: '100%', alignItems: 'center', position: 'relative' },
  objectsPNG: {
    width: moderateScale(818.62),
    height: moderateScale(250.99),
    position: 'absolute',
    top: isWebResponsive ? -100 : 0,
  },
  earthPNG: {
    position: 'absolute',
    bottom: '10%',
    right: '45%',
    width: moderateScale(30.98),
    height: moderateScale(31.07),
  },
  astroCAT: {
    width: moderateScale(380),
    height: moderateScale(294),
  },
  btn: { backgroundColor: Colors.transparentWhite, marginVertical: moderateScale(40), padding: 0 },
  btnText: { textDecorationLine: 'underline', fontSize: moderateScale(16) },
});
