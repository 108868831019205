import { StyleSheet } from 'react-native';

import { Colors } from '@theme';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 40,
  },
  line: {
    flex: 1,
    height: 2,
    backgroundColor: Colors.disabled,
  },
  text: {
    fontFamily: 'InterTight-Bold',
    fontSize: 12,
    marginHorizontal: 8,
    color: Colors.disabled,
  },
});

export default styles;
