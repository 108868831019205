import React from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

const AppleButtonLogo = (props: SvgProps) => (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <Path
      d="M21.1413 16.6737C20.5973 18.319 18.7304 21.9723 16.5612 21.9992C15.3054 21.9992 14.5734 21.2 13.1497 21.2C11.6857 21.2 10.8932 21.9723 9.76498 21.9992C7.60926 22.0663 5.54756 18.0504 4.97673 16.4118C4.54693 15.1626 4.33203 13.9538 4.33203 12.7853C4.33203 8.82309 6.97799 6.87555 9.46949 6.83526C10.6783 6.83526 12.2095 7.71501 12.881 7.71501C13.5123 7.71501 15.2449 6.66737 16.8298 6.78825C18.502 6.91585 19.7713 7.58069 20.6107 8.78279C19.1131 9.69612 18.3677 10.9318 18.3811 12.5704C18.3811 12.651 18.2267 15.3037 21.1413 16.6737ZM12.7534 6.7681C12.6527 5.53914 13.1161 4.44449 13.8951 3.55803C14.607 2.732 15.8493 2.06044 16.9306 2C16.9641 2.47681 17.0783 3.79308 15.8561 5.23694C14.983 6.25771 13.922 6.85541 12.7534 6.7681Z"
      fill="white"
    />
  </Svg>
);

export default AppleButtonLogo;
