import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useScript, appleAuthHelpers } from 'react-apple-signin-auth';
import { CommonActions } from '@react-navigation/core';
import { AxiosError } from 'axios';

import { useLoginWithApple } from '@services/useLogin';
import { getEnv } from '@config/env';
import { setAuthTokenHandler, useStore } from '@store/index';
import { setAuthToken, setRefreshToken } from '@utils/session';
import { useTranslations } from '@hooks/useTranslation';
import toast from '@utils/toast';

const APPLE_CLIENT_ID = 'us.gato.www';

const useAppleId = () => {
  // hooks
  const loginWithApple = useLoginWithApple();
  const setStoreToken = useStore(setAuthTokenHandler);
  const i18n = useTranslations();
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  // states
  const [state, setState] = useState('');
  const [nonce, setNonce] = useState('');

  // Functions
  const generateState = () => uuid();

  const generateNonce = async () => {
    const newUuid = uuid();

    const bufferToHex = (buffer: any) =>
      Array.from(new Uint8Array(buffer))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

    // Función para hashear usando SHA-256
    const hashNonce = async (plainNonce: string) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(plainNonce);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      return bufferToHex(hashBuffer);
    };

    const hash = await hashNonce(newUuid);

    return hash;
  };

  const handleAppleId = async () => {
    try {
      const response = await appleAuthHelpers.signIn({
        authOptions: {
          clientId: APPLE_CLIENT_ID,
          redirectURI: window.location.origin,
          scope: 'email name',
          state,
          nonce,
          usePopup: true,
        },
      });

      if (!response) {
        return;
      }

      loginWithApple.mutate(
        {
          code: response.authorization.code,
          idToken: response.authorization.id_token,
          state: response.authorization.state || state,
          nonce,
          user: response.user,
        },
        {
          onSuccess: (data) => {
            setAuthToken(data.access_token);
            setStoreToken(data.access_token);
            setRefreshToken(data.refreshToken);
            CommonActions.reset({
              routes: [{ name: 'Home' }],
            });
          },
          onError: (error) => {
            if (error instanceof AxiosError) {
              const message = i18n.t(error?.response?.data.translate);
              toast.danger({
                title: message,
                icon: 'error',
              });
            }
          },
        }
      );
    } catch (error) {
      toast.danger({
        title: i18n.t('login.appleid_error'),
        icon: 'error',
      });
    }
  };

  // Effects
  useEffect(() => {
    const get = async () => {
      const state1 = generateState();
      const nonce1 = await generateNonce();
      setState(state1);
      setNonce(nonce1);
    };
    get();
  }, []);

  return {
    handleAppleId,
  };
};

export default useAppleId;
