import { View, Image, ImageSourcePropType, Pressable } from 'react-native';
import React, { FC } from 'react';
import { StackActions, useNavigation } from '@react-navigation/core';
import { MotiView } from 'moti';

import { Game } from '@interfaces/gameDetail';
import CustomText from '@atoms/CustomText';
import Button from '@atoms/Button';
import { useTranslations } from '@hooks/useTranslation';
import { setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { getGameSlug } from '@utils/common';

import styles from './styles';

export const MightGameCardResponsive: FC<Game> = (props): JSX.Element => {
  // constants
  const { name, principalImage } = props;
  const source: ImageSourcePropType = { uri: principalImage || '' };

  // hooks
  const i18n = useTranslations();
  const { dispatch } = useNavigation();
  const gameSlug = getGameSlug(name);

  // store
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);

  // functions
  function handlePress(): void {
    const navigateAction = StackActions.push('GamePage', {
      name: gameSlug,
      autoStartGame: true,
    });
    dispatch(StackActions.pop());
    dispatch(navigateAction);
    toggleModal();
    setModalInfo(undefined);
  }

  // render
  return (
    <Pressable onPress={handlePress}>
      <MotiView
        style={styles.card}
        from={{ bottom: -50, opacity: 0 }}
        animate={{ bottom: 0, opacity: 1 }}
        delay={300}>
        <Image source={source} style={styles.img} />
        <CustomText color="white" align="center" size="lmedium" numberOfLines={2}>
          {name}
        </CustomText>
        <View style={styles.descriptionWrapper}>
          <Button
            onPress={handlePress}
            secondary
            customTextStyle={styles.buttonText}
            customStyle={styles.button}>
            {i18n.t('game_page.play_game')}
          </Button>
        </View>
      </MotiView>
    </Pressable>
  );
};
