import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { IS_HIGH_SCREEN } from '@constants/platform';

export const styles = StyleSheet.create({
  mainContainer: {
    marginTop: moderateScale(16),
    backgroundColor: Colors.darkGrey,
    borderRadius: 16,
    paddingVertical: moderateScale(40),
    paddingHorizontal: moderateScale(64),
    width: '48%',
    minWidth: moderateScale(405),
    justifyContent: 'space-between',
    height: IS_HIGH_SCREEN ? moderateScale(480) : moderateScale(230),
  },
  flexWrapper: { flexDirection: 'row', alignItems: 'center' },
});
