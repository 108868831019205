import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

const styles = StyleSheet.create({
  socialLoginButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: moderateScale(16),
    marginBottom: moderateScale(20),
  },
});

export default styles;
