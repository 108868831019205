import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { isTablet, isWebResponsive, SCREEN_HEIGHT } from '@constants/platform';
import { LARGE } from 'app/theme/spacing';
import { Colors, Spacing } from '@theme';
import { INTER_BOLD } from 'app/theme/fonts';

// eslint-disable-next-line no-nested-ternary
const widthSize = isWebResponsive ? moderateScale(300) : isTablet ? moderateScale(464) : moderateScale(526);

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: SCREEN_HEIGHT - moderateScale(120),
    paddingVertical: '32px',
    paddingHorizontal: Spacing.LATERALPLUS,
  },
  desktopForm: {
    flex: 2,
    width: widthSize,
    maxHeight: moderateVerticalScale(721),
    marginBottom: LARGE,
  },
  onboarding: {
    flex: 0.5,
    backgroundColor: Colors.blackTwo,
    justifyContent: 'center',
  },
  socialLogin: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: isWebResponsive ? moderateScale(42) : moderateScale(54),
  },
  socialLoginDivider: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: moderateScale(20),
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    borderBottomStyle: 'solid',
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: moderateScale(13),
  },
  underline: {
    textDecorationLine: 'underline',
  },
  button: {
    width: '100%',
    height: moderateScale(48),
    paddingVertical: moderateScale(8),
  },
  textButton: {
    fontSize: moderateScale(16),
    fontFamily: INTER_BOLD,
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
  input: {
    backgroundColor: Colors.darkGrey,
    padding: moderateScale(16),
    borderRadius: 16,
    height: moderateScale(48),
    maxWidth: widthSize,
  },
});
