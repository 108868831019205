export enum QueryKeys {
  GET_HOME_CAROUSELS = 'GET_HOME_CAROUSELS',
  GET_GAME_DETAIL = 'GET_GAME_DETAIL',
  GET_GENRES = 'GET_GENRES',
  GET_MOODS = 'GET_MOODS',
  GET_GRAPHIC_STYLES = 'GET_GRAPHIC_STYLES',
  GET_USER_DATA = 'GET_USER_DATA',
  GET_LAST_VERSION = 'GET_LAST_VERSION',
  GET_USER_PROFILE = 'GET_USER_PROFILE',
  GET_LIKED_GAMES = 'GET_LIKED_GAMES',
  GET_GAMES_CREATOR = 'GET_GAMES_CREATOR',
  GET_FOLLOWS = 'GET_FOLLOWS',
  GET_PROFILE_BY_ID = 'GET_PROFILE_BY_ID',
  GET_AVATAR_IMAGES = 'GET_AVATAR_IMAGES',
  GET_LAST_TWO_COMMENTS = 'GET_LAST_TWO_COMMENTS',
  GET_ALL_COMMENTS = 'GET_ALL_COMMENTS',
  GET_THREAD_COMMENTS = 'GET_THREAD_COMMENTS',
  GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS',
  GET_NOTIFICATIONS_UNREAD_COUNT = 'GET_NOTIFICATIONS_UNREAD_COUNT',
  GET_WEB_COMMENTS = 'GET_WEB_COMMENTS',
  GET_DASHBOARD_CREATOR = 'GET_DASHBOARD_CREATOR',
  GET_DASHBOARD_EARNINGS = 'GET_DASHBOARD_EARNINGS',
  GET_ADVERTISING_BY_DIMENSION = 'GET_ADVERTISING_BY_DIMENSION',
  GET_GAME_EVENTS = 'GET_GAME_EVENTS',
}
