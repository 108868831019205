import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useFocusEffect } from '@react-navigation/core';
import { AxiosError } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { Colors } from '@theme';
import useTimer from '@hooks/useTimer';
import useAppCurrentVisibility from '@hooks/useAppCurrentVisibility';
import { useMetricsSession } from '@services/useMetricsSession';
import toast from '@utils/toast';
import { useStore, authTokenSelector } from '@store/index';
import { useUserData } from '@services/useUserData';
import useCloseTab from '@hooks/useCloseTab';
import useNavigatorBackButton from '@hooks/useNavigatorBackButton';

import { RootStackNavigator } from '../../../navigation/types';

import useLeaveGameModal from './useLeaveGameModal';
import { styles } from './styles';

type GameWebViewProps = NativeStackScreenProps<RootStackNavigator, 'GameWebView'>;

export enum GameViewParams {
  LINK = 'resourceLink',
  NAME = 'name',
  ID = 'gameId',
}

const GameWebView = ({ route, navigation }: GameWebViewProps) => {
  const resourceLink = route?.params?.resourceLink;
  const backInRight = route?.params?.backInRight;
  const { name, gameId } = route?.params || {};
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef<any>(null);
  const { seconds, startTimer, pauseTimer, resumeTimer } = useTimer();
  const metricsSession = useMetricsSession();
  const authToken = useStore(authTokenSelector);
  const userId = useUserData(authToken || '');

  const appVisibility = useAppCurrentVisibility();
  const [inactivityDate, setInactivityDate] = useState<Date | null>();
  const [inactiveSeconds, setInactiveSeconds] = useState(0);

  async function saveSessionInDatabase() {
    await AsyncStorage.getItem('sessionSeconds').then((res) => {
      const values = {
        gameId,
        totalTime: Number(res) || seconds,
        userId: userId?.data?.id || '',
      };
      metricsSession.mutate(values, {
        onSuccess: () => {
          setInactivityDate(null);
          startTimer();
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = error?.response?.data.message;
            toast.danger({
              icon: 'error',
              title: message,
            });
          }
        },
      });
    });
  }

  const calculatePlayTime = async () => {
    pauseTimer();
    await saveSessionInDatabase();
  };

  const handleLeaveGame = useLeaveGameModal(calculatePlayTime, name);

  useCloseTab(async (e: BeforeUnloadEvent) => {
    e.preventDefault();
    calculatePlayTime();
  });

  useNavigatorBackButton(async () => {
    calculatePlayTime();
  });

  // T0D0: fix this. We have to use a logic similar to this but on the iframe component
  // useFocusTab(() => {
  //   console.log('focus tab');
  //   if (seconds === 0) {
  //     startTimer();
  //   } else {
  //     resumeTimer();
  //   }
  //   if (inactivityDate) {
  //     let now = new Date();
  //     let diff = Math.abs(now.getTime() - inactivityDate?.getTime()!);
  //     let secondsDiff = Math.floor(diff / 1000);
  //     setInactiveSeconds(seconds + secondsDiff);
  //     setInactivityDate(null);
  //   }
  // })

  // useLeaveTab(() => {
  //   console.log('leave tab');
  //   pauseTimer();
  //   setInactivityDate(new Date());
  // })

  useEffect(() => {
    const handleBackgroundActivity = () => {
      pauseTimer();
      setInactivityDate(new Date());
    };

    const handleActiveActivity = () => {
      if (seconds === 0) {
        startTimer();
      } else {
        resumeTimer();
      }
    };

    if (appVisibility === 'background' || appVisibility === 'inactive') {
      handleBackgroundActivity();
    } else if (appVisibility === 'active') {
      handleActiveActivity();
    }
  }, [appVisibility]);

  const handleError: React.ReactEventHandler<HTMLIFrameElement> = (syntheticEvent) => {
    const { nativeEvent } = syntheticEvent;
    // eslint-disable-next-line no-console
    console.warn('WebView error: ', nativeEvent);
  };

  useFocusEffect(() => {
    navigation.setOptions({
      title: `Game | ${name}`,
    });
  });

  const handleIframeLoad = () => {
    startTimer();
    setIsLoading(false);
  };

  return (
    <Layout padding={false} withScroll={false}>
      {isLoading && (
        <View style={styles.loading}>
          <ActivityIndicator size={64} color={Colors.greySix} />
        </View>
      )}
      <iframe
        title={`Game | ${name}`}
        src={resourceLink}
        height="100%"
        width="100%"
        style={styles.iframe}
        onError={handleError}
        ref={iframeRef}
        onLoad={handleIframeLoad}
      />
      <View style={[styles.header, backInRight ? styles.backInRight : null]}>
        <GoBackButton fromRight={backInRight} gatoLogo={false} goBackAction={handleLeaveGame} />
      </View>
    </Layout>
  );
};

export default GameWebView;
