import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { DESKTOP_CONTAINER_WIDTH } from 'app/theme/spacing';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.blackFour,
  },
  innerContainer: {
    paddingVertical: Spacing.LARGE,
    width: '100%',
    maxWidth: DESKTOP_CONTAINER_WIDTH,
    paddingHorizontal: Spacing.BIG,
    alignSelf: 'center',
  },
  top: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  legal: {
    alignSelf: 'center',
  },
  listItem: {
    marginBottom: Spacing.SMALL,
  },
  columnCenter: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  creatorTitle: {
    marginBottom: Spacing.SMALL,
  },
  creatorSection: {},
});

export const mediaStyles = StyleSheet.create({
  mediaButtonsContainer: {
    flexDirection: 'row',
    marginTop: Spacing.SMALL,
    marginRight: -Spacing.TINY,
  },
  wrapper: { width: '100%', alignItems: 'center' },
  mediaButton: {
    marginRight: Spacing.TINY,
  },
});

export const storeSectionStyles = StyleSheet.create({
  container: {
    marginBottom: Spacing.XXLARGE,
  },
  slogan: {
    paddingVertical: Spacing.MEDIUM,
  },
  buttons: {
    flexDirection: 'row',
    marginRight: -Spacing.TINY,
  },
  button: {
    backgroundColor: Colors.blackTwo,
    marginRight: Spacing.TINY,
    paddingHorizontal: Spacing.MEDIUM,
  },
  buttonText: {
    alignItems: 'center',
  },
  storeIcon: {
    marginBottom: -3.5,
    marginRight: Spacing.TINY,
  },
});

export const creatorSectionStyles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
  },
  smallContainer: {
    marginTop: Spacing.BIG,
    marginBottom: Spacing.XLARGE,
  },
  creatorText: {
    marginTop: Spacing.SMALL,
    marginBottom: Spacing.SMALL + 4,
  },
  creatorButton: {
    paddingHorizontal: Spacing.BIG,
    paddingVertical: Spacing.SMALL,
  },
  buttonLeft: {
    alignSelf: 'flex-end',
  },
});
