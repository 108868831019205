import React from 'react';
import { Pressable, View } from 'react-native';

import { CustomModal } from '@molecules/CustomModal';
import CustomText from '@atoms/CustomText';
import { modalOpenSelector, toggleModalHandler, useStore, modalInfoSelector } from '@store/index';
import { isDesktop } from '@constants/platform';
import GamesPostPlay from '@molecules/GamesPostPlay';
import { moderateScale } from '@utils/scaling';

import { styles } from './styles';

export const DoubleConfirmModal = () => {
  const modalOpen = useStore(modalOpenSelector);
  const modalInfo = useStore(modalInfoSelector);
  const toggleModal = useStore(toggleModalHandler);

  const handleClose = () => {
    toggleModal();
  };

  const textSize = isDesktop ? 'big' : 'xmedium';

  return (
    <>
      <CustomModal
        visible={modalOpen}
        onOverlayPress={handleClose}
        customCardStyle={{ marginBottom: modalInfo?.postPlayGames?.length ? moderateScale(40) : 0 }}>
        <View>
          <CustomText numberOfLines={2} customStyle={styles.message} size={textSize} weight="interSemi">
            {modalInfo?.message}
          </CustomText>
          {modalInfo?.body ? (
            <CustomText align="center" size="xmedium" weight="interRegular" color="greyThree">
              {modalInfo?.body}
            </CustomText>
          ) : null}
          <View style={[styles.row, isDesktop && styles.rowDesktop]}>
            <Pressable
              onPress={modalInfo?.confirmAction}
              style={[styles.button, styles.confirm, isDesktop && styles.buttonDesktop]}>
              <CustomText size="xmedium" color="darkGrey" weight="interSemi">
                {modalInfo?.confirmText}
              </CustomText>
            </Pressable>
            <Pressable
              onPress={modalInfo?.cancelAction}
              style={[styles.button, styles.cancel, isDesktop && styles.buttonDesktop]}>
              <CustomText size="xmedium" color="white" weight="interSemi">
                {modalInfo?.cancelText}
              </CustomText>
            </Pressable>
          </View>
        </View>
      </CustomModal>
      {modalInfo?.postPlayGames?.length && modalOpen ? (
        <GamesPostPlay games={modalInfo?.postPlayGames} />
      ) : null}
    </>
  );
};
