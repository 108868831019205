import { StyleSheet } from 'react-native';

import { Sizes } from '@atoms/CustomText';
import { isTablet, isWebResponsive } from '@constants/platform';
import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
// constants
export const responsiveFontSize: Sizes[] = isWebResponsive ? ['lmedium', 'msmall'] : ['xbig', 'xmedium'];

const responsiveHeight: number = isWebResponsive ? moderateScale(71) : moderateScale(104);

const responsiveVPadding: number = isWebResponsive ? moderateScale(16) : moderateScale(24);

const responsiveHPadding: number = isWebResponsive ? moderateScale(16) : moderateScale(56);

export default StyleSheet.create({
  main: {
    backgroundColor: Colors.blackTwo,
    marginBottom: isWebResponsive ? moderateScale(32) : moderateScale(56),
    width: '100%',
    height: isTablet ? moderateScale(81) : responsiveHeight,
    paddingVertical: isTablet ? moderateScale(16) : responsiveVPadding,
    paddingHorizontal: isTablet ? moderateScale(32) : responsiveHPadding,
    borderRadius: moderateScale(16),
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  roundedButton: { backgroundColor: Colors.limeGreen },
  arrow: {
    transform: [{ rotate: '180deg' }, { scale: isWebResponsive ? 0.5 : 0.75 }],
  },
});
