import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Pressable, ScrollView, View } from 'react-native';
import { AxiosError } from 'axios';
import BottomSheet, { BottomSheetScrollView } from '@gorhom/bottom-sheet';
// import { ScrollView } from 'react-native-gesture-handler';

import FullnameIcon from '@assets/svg/FullnameIcon';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import UsernameIcon from '@assets/svg/UsernameIcon';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import { RegisterParams } from '@interfaces/auth';
import Field from '@molecules/Field';
import { Layout } from '@organisms/Layout';
import { useRegister } from '@services/useRegister';
import toast from '@utils/toast';
import { RootStackNavigator } from 'app/navigation/types';
import EmailIcon from '@assets/svg/EmailIcon';
import Checkbox from '@atoms/Checkbox';
import { emailRegex, onlyStringsRegex, passwordRegex, usernameRegex } from '@utils/validations';
import { Colors } from '@theme';
import RoundedButton from '@atoms/RoundedButton';
import CloseIcon from '@assets/svg/CloseIcon';
import { useDebounce } from '@hooks/useDebounce';
import { useValidateUsername } from '@services/useValidateUsername';
import { useValidateEmail } from '@services/useValidateEmail';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import GoBackButton from '@molecules/GoBackButton';
import DesktopCenteredContainer from '@atoms/DesktopCenteredContainer';
import Row from '@atoms/Row';
import { CustomModal } from '@molecules/CustomModal';
import { moderateVerticalScale } from '@utils/scaling';
import GATOLogo from '@assets/svg/GATOLogo';
import SocialLoginButtons from '@molecules/SocialLoginButtons';

import DividerWithText from '../DividerWithText';

import { styles } from './styles';

type RegisterProps = NativeStackScreenProps<RootStackNavigator, 'Register'>;

export const Register = ({ navigation }: RegisterProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(false);
  useStatusBar('light');
  const sheetRef = useRef<BottomSheet>(null);
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [continueWithEmailAndPass, setContinueWithEmailAndPass] = useState<boolean>(false);

  const i18n = useTranslations();
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
  } = useForm<RegisterParams>({ mode: 'onChange' });

  const passwordRef = React.useRef({});
  passwordRef.current = watch('password', '');
  const snapPoints = useMemo(() => ['50%'], []);

  const register = useRegister();
  const isDisabled =
    !watch('userName') ||
    !watch('fullName') ||
    !watch('password') ||
    !watch('email') ||
    !termsAccepted ||
    Boolean(errors.fullName?.message) ||
    Boolean(errors.email?.message) ||
    Boolean(errors.password?.message) ||
    Boolean(errors.repeatPassword?.message) ||
    Boolean(errors.userName?.message);

  const debouncedUserName = useDebounce(watch('userName'), 1000);
  const debouncedEmail = useDebounce(watch('email'), 1000);

  const validUserName = useValidateUsername();
  const validEmail = useValidateEmail();

  const handleLogin = () => {
    navigation.navigate('Login');
  };

  function onSubmit(values: RegisterParams) {
    register.mutate(values, {
      onSuccess: (response) => {
        navigation.navigate('ValidateCode', {
          userId: response.id,
          userEmail: response.email,
          type: 'VERIFY',
          password: values.password,
        });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        }
      },
    });
  }

  const handleClose = () => {
    sheetRef.current?.close();
    setTermsModalVisible(false);
  };

  const handleOpenModal = () => {
    sheetRef.current?.snapToIndex(0);
    setTermsModalVisible(true);
  };

  const handleAcceptTerms = () => {
    setTermsAccepted((prevTermsAccepted) => !prevTermsAccepted);
  };

  const handleAcceptModal = () => {
    if (!termsAccepted) handleAcceptTerms();
    handleClose();
  };

  const handleDownload = () => {
    handleClose();
    navigation.navigate('TermsAndConditions');
  };

  const handle = () => (
    <View style={isMobile ? styles.header : styles.desktopModalHeader}>
      <View style={styles.border}>
        <CustomText size="big" weight="interSemi">
          {i18n.t('register.terms_2')}
        </CustomText>
        <RoundedButton action={handleClose}>
          <CloseIcon />
        </RoundedButton>
      </View>
    </View>
  );

  useEffect(() => {
    if (debouncedUserName && !errors.userName?.message) {
      validUserName.mutate(debouncedUserName, {
        onSuccess: () => {
          setUsernameAvailable(true);
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = i18n.t(error?.response?.data.translate);
            setError('userName', { message });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserName]);

  useEffect(() => {
    if (debouncedEmail && !errors.email?.message) {
      validEmail.mutate(debouncedEmail, {
        onSuccess: () => {
          setEmailAvailable(true);
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = error?.response?.data.message;
            setError('email', { message });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmail]);

  const textAlign = isMobile || isWebResponsive ? 'left' : 'center';

  return (
    <>
      <Layout
        withScroll
        verticallyCentered={isDesktop}
        backgroundColor={isDesktop && !isWebResponsive ? Colors.blackThree : undefined}>
        {isDesktop ? (
          <GoBackButton
            customStyles={isWebResponsive && styles.backBtn}
            goBackAction={handleLogin}
            gatoLogo={false}
          />
        ) : null}
        <View style={styles.container}>
          <DesktopCenteredContainer style={styles.desktopForm}>
            <View>
              <View>
                <RegisterHeader />

                <Field
                  autoComplete="off"
                  icon={EmailIcon}
                  name="email"
                  isAvailable={emailAvailable}
                  type="email-address"
                  control={control}
                  placeholder={i18n.t('register.email_placeholder')}
                  getValues={getValues}
                  rules={{
                    required: i18n.t('register.required'),
                    pattern: {
                      value: emailRegex,
                      message: i18n.t('register.email_pattern_error'),
                    },
                  }}
                  error={errors}
                  containerStyles={styles.input}
                />

                {continueWithEmailAndPass && (
                  <>
                    <Row>
                      <Field
                        icon={FullnameIcon}
                        name="fullName"
                        control={control}
                        placeholder={i18n.t('register.fullname_placeholder')}
                        getValues={getValues}
                        rules={{
                          required: i18n.t('register.required'),
                          minLength: {
                            value: 3,
                            message: i18n.t('register.fullname_min_length_error'),
                          },
                          pattern: {
                            value: onlyStringsRegex,
                            message: i18n.t('register.fullname_pattern_error'),
                          },
                        }}
                        focusText={i18n.t('register.fullname_text')}
                        error={errors}
                        autoCapitalize="words"
                        containerStyles={styles.input}
                      />
                      <Field
                        icon={UsernameIcon}
                        name="userName"
                        isAvailable={usernameAvailable}
                        control={control}
                        placeholder={i18n.t('register.username_placeholder') as string}
                        getValues={getValues}
                        rules={{
                          required: i18n.t('register.required') as string,
                          maxLength: {
                            value: 20,
                            message: i18n.t('register.username_max_lenght_error'),
                          },
                          minLength: {
                            value: 3,
                            message: i18n.t('register.username_min_length_error'),
                          },
                          pattern: {
                            value: usernameRegex,
                            message: i18n.t('register.username_pattern_error'),
                          },
                        }}
                        focusText={i18n.t('register.username_text') || ''}
                        error={errors}
                        maxLength={20}
                        containerStyles={styles.input}
                      />
                    </Row>
                    <Row>
                      <Field
                        icon={PasswordInputIcon}
                        name="password"
                        control={control}
                        placeholder={i18n.t('register.password_placeholder') || ''}
                        rules={{
                          required: i18n.t('register.required') || '',
                          pattern: {
                            value: passwordRegex,
                            message: i18n.t('register.password_min_lenght_error'),
                          },
                        }}
                        secure
                        focusText={i18n.t('register.password_text') || ''}
                        getValues={getValues}
                        error={errors}
                        maxLength={20}
                        // eslint-disable-next-line react-native/no-inline-styles
                        errorStyle={{ maxWidth: 300 }}
                        onFocus={() => setValue('repeatPassword', '')}
                        containerStyles={styles.input}
                      />
                      <Field
                        icon={PasswordInputIcon}
                        name="repeatPassword"
                        error={errors}
                        getValues={getValues}
                        secure
                        control={control}
                        placeholder={i18n.t('register.repeat_password_placeholder') || ''}
                        rules={{
                          required: i18n.t('register.required') || '',
                          validate: (value) =>
                            value === passwordRef.current || i18n.t('register.repeat_password_pattern_error'),
                        }}
                        containerStyles={styles.input}
                      />
                    </Row>
                    <Checkbox
                      active={termsAccepted}
                      item={{ name: 'terms', id: 0 }}
                      onSelect={handleAcceptTerms}
                      containerCustomStyle={styles.checkContainer}
                      customTextComponent={
                        <CustomText weight="interRegular" size="medium" color="greySix" align="left">
                          {`${i18n.t('register.terms_1')} `}
                          <Pressable style={styles.checkPress} onPress={handleOpenModal}>
                            <CustomText
                              weight="interBold"
                              size="medium"
                              customStyle={styles.underline}
                              color="greySeven"
                              align="left">
                              {`${i18n.t('register.terms_2')}`}
                            </CustomText>
                          </Pressable>
                          <CustomText weight="interRegular" size="medium" color="greySix" align="left">
                            {' '}
                            {i18n.t('register.terms_3')}
                          </CustomText>
                        </CustomText>
                      }
                    />
                    <View style={{ marginBottom: 28 }}>
                      <Button
                        disabled={isDisabled}
                        loading={register.isLoading}
                        customStyle={styles.button}
                        onPress={handleSubmit(onSubmit)}>
                        {i18n.t('register.button_action')}
                      </Button>
                    </View>
                  </>
                )}

                {!continueWithEmailAndPass && (
                  <Button
                    loading={register.isLoading}
                    customStyle={styles.button}
                    disabled={!watch('email')}
                    onPress={() => setContinueWithEmailAndPass(true)}>
                    {i18n.t('register.continue')}
                  </Button>
                )}
              </View>

              {!continueWithEmailAndPass && (
                <>
                  {/* Divider OR */}
                  <DividerWithText />
                  <View style={styles.socialLogin}>
                    <CustomText
                      color="greySix"
                      size="medium"
                      weight="interBold"
                      customStyle={{ marginBottom: 16 }}>
                      {i18n.t('login.continue_with')}
                    </CustomText>
                    <SocialLoginButtons />
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <CustomText
                      customStyle={{ width: 280 }}
                      weight="interRegular"
                      size="medium"
                      color="greySix"
                      align="center">
                      {`${i18n.t('register.terms_1')} `}
                      <Pressable style={styles.checkPress} onPress={handleOpenModal}>
                        <CustomText
                          weight="interBold"
                          size="medium"
                          customStyle={styles.underline}
                          color="greySeven"
                          align="left">
                          {`${i18n.t('register.terms_2')}`}
                        </CustomText>
                      </Pressable>
                      <CustomText weight="interRegular" size="medium" color="greySix" align="left">
                        {' '}
                        {i18n.t('register.terms_3')}
                      </CustomText>
                    </CustomText>
                  </View>
                </>
              )}

              <View style={styles.register}>
                <CustomText color="greySix" size="medium" weight="interBold">
                  {`${i18n.t('register.already_have_account')}  `}
                </CustomText>
                <Pressable hitSlop={10} onPress={handleLogin}>
                  <CustomText
                    customStyle={styles.underline}
                    size="medium"
                    color="limeGreen"
                    weight="interBold">
                    {i18n.t('register.login')}
                  </CustomText>
                </Pressable>
              </View>
            </View>
          </DesktopCenteredContainer>
        </View>
      </Layout>
      {isDesktop && (
        <CustomModal visible={termsModalVisible} onOverlayPress={handleClose} cardColor={Colors.darkGrey}>
          <View style={{ height: moderateVerticalScale(500) }}>
            {handle()}
            <ScrollView>
              <CustomText color="greyEight" customStyle={styles.termsText} align="left" weight="interMedium">
                {i18n.t('register.terms')}
              </CustomText>
            </ScrollView>
            <View style={styles.desktopModalFooter}>
              <Button customStyle={styles.desktopModalButton} onPress={handleAcceptModal}>
                {i18n.t('register.terms_accept')}
              </Button>
              <Button customStyle={styles.desktopModalButton} secondary onPress={handleDownload}>
                {i18n.t('register.read_tyc')}
              </Button>
            </View>
          </View>
        </CustomModal>
      )}
    </>
  );
};

const RegisterHeader = () => {
  const i18n = useTranslations();

  return (
    <>
      <GATOLogo style={styles.logoImage} />
      <CustomText weight="interBold" align="center" size="xlarge">
        {`${i18n.t('register.title_1')} `}
        <CustomText weight="interBold" size="xlarge" color="limeGreen">
          {i18n.t('register.title_2')}
        </CustomText>
      </CustomText>
      <CustomText customStyle={styles.description} weight="interRegular" align="center" size="xbig">
        {i18n.t('register.welcome')}
      </CustomText>
    </>
  );
};

interface RegisterButtonProps {
  handleOpenModal: () => void;
}

const LoginSocial = ({ handleOpenModal }: RegisterButtonProps) => {
  const i18n = useTranslations();

  return (
    <>
      <DividerWithText />
      <CustomText color="greySix" size="medium" weight="interBold" customStyle={{ marginBottom: 16 }}>
        {i18n.t('login.continue_with')}
      </CustomText>
      <SocialLoginButtons />
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <CustomText
          customStyle={{ width: 280 }}
          weight="interRegular"
          size="medium"
          color="greySix"
          align="center">
          {`${i18n.t('register.terms_1')} `}
          <Pressable style={styles.checkPress} onPress={handleOpenModal}>
            <CustomText
              weight="interBold"
              size="medium"
              customStyle={styles.underline}
              color="greySeven"
              align="left">
              {`${i18n.t('register.terms_2')}`}
            </CustomText>
          </Pressable>
          <CustomText weight="interRegular" size="medium" color="greySix" align="left">
            {' '}
            {i18n.t('register.terms_3')}
          </CustomText>
        </CustomText>
      </View>
    </>
  );
};
