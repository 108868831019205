import React, { FC } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { Colors } from '@theme';
import { isMobile } from '@constants/platform';
import { moderateScale } from '@utils/scaling';

import styles from './styles';
import { ButtonProps } from './types';

const Button: FC<ButtonProps> = ({
  onPress,
  children,
  disabled,
  loading,
  customStyle = {},
  customTextStyle = {},
  fixed,
  secondary,
  terciary,
  testID = '',
  accessibilityLabelValue = '',
  icon,
  iconPosition = 'left',
  ...props
}): JSX.Element => {
  const onPressHandler = () => {
    if (!disabled && onPress) onPress();
  };
  return (
    <Pressable
      {...props}
      accessibilityLabel={accessibilityLabelValue}
      onPress={onPressHandler}
      testID={testID}
      disabled={loading || disabled}
      style={({ pressed }) => [
        styles.button,
        (disabled || loading) && styles.disabled,
        fixed && styles.fixedBottom,
        secondary && styles.secondary,
        terciary && styles.terciary,
        secondary && disabled && styles.disabledSecondary,
        terciary && disabled && styles.disabledTerciary,
        pressed && (!secondary || !terciary) && { backgroundColor: Colors.buttonPrimPressed },
        pressed && secondary && { backgroundColor: Colors.blackTwo },
        pressed && terciary && { backgroundColor: Colors.purple },
        customStyle,
      ]}>
      {!loading && icon && iconPosition === 'left' ? (
        <View style={{ marginRight: moderateScale(5) }}>{icon}</View>
      ) : null}
      {loading ? (
        <ActivityIndicator color={secondary || terciary ? Colors.white : Colors.black} size="small" />
      ) : (
        <CustomText
          size={isMobile ? 'medium' : 'xmedium'}
          customStyle={customTextStyle}
          color={secondary || terciary || disabled ? 'white' : 'black'}
          numberOfLines={1}
          weight="interSemi">
          {children}
        </CustomText>
      )}
      {!loading && icon && iconPosition === 'right' ? (
        <View style={{ marginLeft: moderateScale(5) }}>{icon}</View>
      ) : null}
    </Pressable>
  );
};

export default React.memo(Button);
