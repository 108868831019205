import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isTablet } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.blackTwo,
  },
  background: {
    resizeMode: 'cover',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  backgroundTexture: {
    resizeMode: 'cover',
    position: 'absolute',
    right: 0,
    top: -200,
  },
  PNGWrapper: { width: '100%', alignItems: 'center', position: 'relative' },
  serverPNG: {
    width: isTablet ? moderateScale(438) : moderateScale(738),
    height: isTablet ? moderateScale(366) : moderateScale(566),
    position: 'absolute',
    bottom: '25%',
    right: isTablet ? '20%' : '25%',
  },
  illustration: {
    width: moderateScale(254),
    height: moderateScale(386),
    position: 'absolute',
    top: '20%',
    right: '20%',
  },

  title: { alignSelf: 'center', position: 'absolute', bottom: '13%' },
  subtitle: { alignSelf: 'center', position: 'absolute', bottom: '8%' },
});
