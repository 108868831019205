/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import * as React from 'react';

import { ErrorFallback } from '@screens/ErrorFallback';
import { GameViewParams } from '@screens/Main/GameWebView/index.web';
import { AdblockModal } from '@organisms/AdblockModal';
import { useStore } from '@store/index';

import { keyExistOnParams } from './setURLParams';

interface ErrorHandlerProps {
  children: React.ReactNode;
}

interface State {
  errorOccurred: boolean;
  adblockDetected: boolean;
}

class ErrorHandler extends React.Component<ErrorHandlerProps, State> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = {
      errorOccurred: false,
      adblockDetected: false,
    };
  }

  static getDerivedStateFromError(_error: Error) {
    return { errorOccurred: true, adblockDetected: false };
  }

  rejectURL(): void {
    if (window.location.pathname === '/ingame' && !keyExistOnParams(GameViewParams.LINK)) {
      return window.location.replace(window.location.origin);
    }
  }

  detectAdBlock(): void {
    const videoJSExists = document.getElementsByTagName('video-js');

    if (!videoJSExists.length && useStore.getState().shownAd) {
      this.setState({ adblockDetected: true, errorOccurred: false });
    }
  }

  componentDidCatch(_error: Error, _info: React.ErrorInfo) {}

  componentDidMount() {
    this.rejectURL();
  }

  componentDidUpdate(_prevProps: Readonly<ErrorHandlerProps>, prevState: Readonly<State>): void {
    if (prevState.errorOccurred !== this.state.errorOccurred && useStore.getState().shownAd) {
      return this.detectAdBlock();
    }
  }

  render() {
    if (this.state.adblockDetected && !this.state.errorOccurred) {
      return <AdblockModal />;
    }
    if (this.state.errorOccurred && !this.state.adblockDetected) {
      return <ErrorFallback />;
    }
    return this.props.children;
  }
}
export default React.memo(ErrorHandler);
