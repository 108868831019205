/* eslint-disable consistent-return */
import React, { useRef } from 'react';
import { View, Pressable, ActivityIndicator } from 'react-native';
import Lottie from 'lottie-react-native';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { CompositeScreenProps } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import share from '@utils/shareApp';
import { MainNavigatorParamList, RootStackNavigator } from 'app/navigation/types';
import CustomText from '@atoms/CustomText';
import { Layout } from '@organisms/Layout';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { UserProfileInfo } from '@atoms/UserProfileInfo';
import { UserFollowing } from '@atoms/UserFollowing';
import { LikedGameList } from '@atoms/LikedGameList';
import { CreatorGameList } from '@atoms/CreatorGameList';
import { useMyProfile } from '@services/useMyProfile';
import { useGetLikedGames } from '@services/useGetLikedGames';
import { useGetGamesByCreator } from '@services/useGetGamesByCreator';
import { isWebResponsive, IS_SMALL_DEVICE, WINDOW_HEIGHT } from '@constants/platform';
import { authTokenSelector, useStore } from '@store/index';
import { Colors } from '@theme';
import toast from '@utils/toast';
import { moderateScale } from '@utils/scaling';

import { buildLink } from '../GamePage/utils';

import { styles } from './styles.web';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../assets/lotties/share.json');

type MyProfileProps = CompositeScreenProps<
  | BottomTabScreenProps<MainNavigatorParamList, 'MyProfile'>
  | NativeStackScreenProps<MainNavigatorParamList, 'MyProfile'>,
  NativeStackScreenProps<RootStackNavigator>
>;

export const MyProfile = ({ navigation }: MyProfileProps) => {
  const authToken = useStore(authTokenSelector);
  const { data } = useMyProfile();
  const i18n = useTranslation();
  const { data: likedGames } = useGetLikedGames(data?.id, data?.type);
  const { data: creatorGames } = useGetGamesByCreator(data?.id, data?.type);

  const animation = useRef<Lottie>(null);

  const animateLottie = () => {
    animation.current?.play();
    setTimeout(() => {
      animation.current?.reset();
    }, 5000);
  };

  const toAccountSettings = () => {
    navigation.push('AccountSettings');
  };

  const shareLink = async () => {
    try {
      const link = await buildLink('profile', data?.userName);
      return link;
    } catch (err) {
      toast.danger({ title: i18n.t('error.error_generic'), icon: 'error' });
    }
  };

  const onShare = async () => {
    animateLottie();
    const sharedLink = await shareLink();
    // const message = `${i18n.t('profile.share_own_profile')} ${data?.fullName} ${i18n.t(
    //   'profile.share_profile_2'
    // )} ^-^ ${sharedLink}\n\n${i18n.t('profile.share_profile_3')}`;

    share(sharedLink);
  };

  return (
    <Layout
      padding
      withScroll
      showScrollIndicator={false}
      tabOffset={IS_SMALL_DEVICE ? 100 : 80}
      desktopFooter>
      {!data ? (
        // LOTTIE LOADER

        <View style={{ height: WINDOW_HEIGHT - moderateScale(120), justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={Colors.greySix} />
        </View>
      ) : (
        <>
          {/* <View style={styles.headerLeft}>
            <Pressable onPress={animateLottie}>
              <View>
                <Lottie
                  autoPlay={false}
                  ref={animation}
                  style={styles.lottie}
                  source={lottie}
                  loop={false}
                  onAnimationFinish={onShare}
                />
              </View>
            </Pressable>
          </View>
          <View style={styles.headerRight}>
            <Pressable onPress={toSettings}>
              <View>
                <Settings />
              </View>
            </Pressable>
          </View> */}

          <View style={styles.headerContainer}>
            <AvatarProfile
              enableAvatarModal
              offsetKitten={5}
              imageUrl={data?.profileImage}
              backgroundUrl={data?.backgroundProfileImage ?? undefined}
            />

            <UserProfileInfo
              fullName={data?.fullName}
              userName={data?.userName}
              userType={data?.type}
              bio={data?.bio}
            />

            <View style={styles.profileContainer}>
              <UserFollowing
                userType={data?.type}
                userId={data?.id}
                authToken={authToken}
                likes={likedGames?.length || 0}
                games={creatorGames ? creatorGames[0]?.games?.length : 0}
                followings={data?.following}
                followers={data?.followers}
              />

              {!isWebResponsive && (
                <View style={styles.profileButtons}>
                  {/* <Link to="/account-settings/overview" style={styles.btn}>
                    <CustomText weight="interSemi">{i18n.t('profile.edit_profile')}</CustomText>
                  </Link> */}
                  <Pressable onPress={toAccountSettings} style={styles.btn}>
                    <CustomText weight="interSemi">{i18n.t('profile.edit_profile')}</CustomText>
                  </Pressable>
                  <Pressable onPress={onShare}>
                    <Lottie
                      autoPlay={false}
                      ref={animation}
                      style={styles.lottie}
                      source={lottie}
                      loop={false}
                    />
                  </Pressable>
                </View>
              )}
            </View>
          </View>

          {data?.type === 'PLAYER' ? (
            <LikedGameList isOwnProfile games={likedGames} />
          ) : (
            <CreatorGameList
              isOwnProfile
              name={data?.userName}
              games={creatorGames ? creatorGames[0]?.games : []}
            />
          )}
        </>
      )}
      <View style={styles.footer} />
    </Layout>
  );
};
