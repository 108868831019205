import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export default StyleSheet.create({
  card: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: moderateScale(112),
    height: '100%',
    minHeight: moderateScale(204),
    backgroundColor: Colors.darkGrey,
    borderRadius: moderateScale(10),
  },
  img: {
    width: moderateScale(112),
    height: moderateScale(112),
    borderTopStartRadius: moderateScale(8),
    borderTopEndRadius: moderateScale(8),
  },
  descriptionWrapper: {
    width: '100%',
    padding: moderateScale(8),
  },
  title: { paddingVertical: moderateScale(5) },
  buttonText: { fontSize: moderateScale(10) },
  button: {
    backgroundColor: Colors.buttonTerciary,
    borderRadius: moderateScale(8),
    width: '100%',
    alignSelf: 'flex-start',
    paddingHorizontal: moderateScale(8),
  },
});
