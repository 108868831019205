import { create } from 'zustand';

interface Store {
  historyPath: Array<string>;
  setHistoryPath: (newPath: string) => void;
}

const useHistoryRoutePath = create<Store>((set) => ({
  historyPath: [],
  setHistoryPath(newPath: string): void {
    if (newPath === '/') {
      set((state) => ({ historyPath: [newPath] }));
      return;
    }

    set((state) => ({ historyPath: [...state.historyPath, newPath] }));
  },
}));

// Selectors
export const historyPathSelector = (state: Store) => state.historyPath;
export const lastHistoryPathSelector = (state: Store) => state.historyPath[state.historyPath.length - 1] || '/';

// Handlers
export const setHistoryPathHandler = (state: Store) => state.setHistoryPath;

export default useHistoryRoutePath;
