import React, { FC, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackHeaderProps } from '@react-navigation/native-stack';
import { PortalProvider } from '@gorhom/portal';

import Notifications from '@screens/Main/Notifications';
import { Splash } from '@screens/Init/Splash/Splash';
import GamePage from '@screens/Main/GamePage/GamePage';
import GameWebView from '@screens/Main/GameWebView';
import { Login } from '@screens/Auth/Login';
import { Register } from '@screens/Auth/Register';
import { ValidateCode } from '@screens/Auth/ValidateCode';
import FiltersModal from '@organisms/FiltersModal';
import { DoubleConfirmModal } from '@organisms/DoubleConfirmModal';
import { ResetPassword } from '@screens/Auth/ResetPassword';
import { ForgotPassword } from '@screens/Auth/ForgotPassword';
import UploadGame from '@screens/Main/UploadGame';
import AccountSettings from '@screens/Main/AccountSettings';
import UserProfile from '@screens/Main/UserProfile';
import ChangeName from '@screens/Main/AccountSettings/ChangeName';
import ChangeUsername from '@screens/Main/AccountSettings/ChangeUsername';
import ChangeBio from '@screens/Main/AccountSettings/ChangeBio';
import ChangePassword from '@screens/Main/AccountSettings/ChangePassword';
import ChangeEmail from '@screens/Main/AccountSettings/ChangeEmail';
import DeleteAccount from '@screens/Main/AccountSettings/DeleteAccount';
import { Token } from '@screens/Token';
import Onboarding from '@screens/Init/Onboarding';
import Settings from '@screens/Main/Settings';
import Follows from '@screens/Main/Follows';
import Home from '@screens/Main/Home/Home';
import Explore from '@screens/Main/Explore';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import { authTokenSelector, setAuthTokenHandler, showAvatarSelector, useStore } from '@store/index';
import { getHasOnboardingSeen } from '@utils/onboarding';
import { getAuthToken } from '@utils/session';
import Comments from '@screens/Main/Comments';
import { SubMenuModal } from '@organisms/SubMenuModal';
// import ChangeAvatar from '@screens/Main/ChangeAvatar';
import AlertAvatar from '@screens/Main/AvatarAlert';
import ChangeAvatarFirstStep from '@screens/Main/ChangeAvatar/ChangeAvatarFirstStep';
import ChangeAvatarSecondStep from '@screens/Main/ChangeAvatar/ChangeAvatarSecondStep';
import ChangeAvatarThirdStep from '@screens/Main/ChangeAvatar/ChangeAvatarThirdStep';
import { MyProfile } from '@screens/Main/MyProfile';
import ChangeAvatarWebModal from '@organisms/ChangeAvatarWebModal';
import { NotificationsModal } from '@organisms/NotificationsModal';
import { MenuModal } from '@organisms/MenuModal';
import TermsAndConditions from '@screens/Main/TermsAndConditions';
import { Search } from '@screens/Main/Search';
import PolicyOfPrivacy from '@screens/Main/PolicyOfPrivacy';
import { Dashboard } from '@screens/Dashboard';
import DeleteAccountWithoutLogin from '@screens/Main/DeleteAccountWithoutLogin';
import { NotFoundPage } from '@screens/Main/NotFound';

import MainNavigator from './MainNavigator';
import { RootStackNavigator } from './types';
import { linking } from './constants';
import WebNavbar from './WebNavbar';
import AccountSettingsNavigator from './AccountSettingsNavigator';

const Stack = createNativeStackNavigator<RootStackNavigator>();

const navbar: FC<NativeStackHeaderProps> = ({ route, options }) => {
  const { headerBackground } = options;
  let { path } = route;
  path = path || (route.params && 'path' in route.params ? (route.params.path as string) : undefined);
  return <WebNavbar HeaderBackground={headerBackground} />;
};

export const screenOptions = { headerShown: isDesktop, header: navbar };

export const AppNavigator = () => {
  const navigationRef = React.useRef(null);
  const authToken = useStore(authTokenSelector);
  const setAuthToken = useStore(setAuthTokenHandler);
  const [hasSeenOnb, setHasSeenOnb] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const showAvatarModal = useStore(showAvatarSelector);

  const handleAppLoading = async () => {
    const seenOnb = await getHasOnboardingSeen();
    const userToken = await getAuthToken();
    if (userToken) setAuthToken(userToken);
    setHasSeenOnb(seenOnb);
    setIsLoading(false);
  };

  // TODO: UNCOMMENT
  if (isLoading) return <Splash handleAnimationFinish={handleAppLoading} />;

  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <PortalProvider>
        <Stack.Navigator screenOptions={screenOptions}>
          {isDesktop && (
            <>
              <Stack.Screen
                name="Home"
                component={isWebResponsive ? MainNavigator : Home}
                options={{ title: `GATO | Let’s play` }}
              />
              <Stack.Screen name="Explore" component={Explore} options={{ title: `GATO | Explore` }} />
              <Stack.Screen name="GamePage" component={GamePage} options={{ title: `Game | ...` }} />
              <Stack.Screen
                name="TermsAndConditions"
                component={TermsAndConditions}
                options={{ title: `Game | Terms and Conditions` }}
              />
              <Stack.Screen
                name="PolicyOfPrivacy"
                component={PolicyOfPrivacy}
                options={{ title: `Game | Policy of Privacy` }}
              />
              <Stack.Screen
                name="UploadGame"
                component={UploadGame}
                options={{ headerShown: false, title: `GATO | Upload game` }}
              />
              <Stack.Screen
                name="UserProfile"
                component={UserProfile}
                options={{ title: `GATO | User profile` }}
              />
              <Stack.Screen
                name="GameWebView"
                component={GameWebView}
                options={{ title: `Game | ...`, headerShown: false }}
              />
            </>
          )}
          <Stack.Screen
            name="DeleteAccountWithoutLogin"
            component={DeleteAccountWithoutLogin}
            options={{ headerShown: true }}
          />
          {!authToken && (
            <>
              {!hasSeenOnb && isMobile && <Stack.Screen name="Onboarding" component={Onboarding} />}
              <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
              <Stack.Screen name="Register" component={Register} options={{ headerShown: false }} />
              <Stack.Screen
                name="ForgotPassword"
                component={ForgotPassword}
                options={{ headerShown: false }}
              />
              <Stack.Screen name="ResetPassword" component={ResetPassword} options={{ headerShown: false }} />
            </>
          )}
          {authToken && (
            <>
              {isMobile && (
                <>
                  <Stack.Screen name="Main" component={MainNavigator} />
                  <Stack.Screen name="GamePage" component={GamePage} />
                  <Stack.Screen name="UploadGame" component={UploadGame} options={{ headerShown: false }} />
                  <Stack.Screen name="Settings" component={Settings} />
                  <Stack.Screen name="Comments" component={Comments} />
                </>
              )}
              <Stack.Screen name="Dashboard" component={Dashboard} options={{ title: `GATO | Dashboard` }} />
              <Stack.Screen name="Token" component={Token} />
              <Stack.Screen name="Follows" component={Follows} />
              <Stack.Screen name="AvatarAlert" component={AlertAvatar} options={{ presentation: 'modal' }} />
              <Stack.Screen
                name="ChangeAvatarFirstStep"
                component={ChangeAvatarFirstStep}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChangeAvatarSecondStep"
                component={ChangeAvatarSecondStep}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChangeAvatarThirdStep"
                component={ChangeAvatarThirdStep}
                options={{ headerShown: false }}
              />
              {isMobile || isWebResponsive ? (
                <>
                  <Stack.Screen name="AccountSettings" component={AccountSettings} />
                  <Stack.Screen name="ChangeName" component={ChangeName} />
                  <Stack.Screen name="ChangeUsername" component={ChangeUsername} />
                  <Stack.Screen name="ChangeBio" component={ChangeBio} />
                  <Stack.Screen name="ChangeEmail" component={ChangeEmail} />
                  <Stack.Screen name="ChangePassword" component={ChangePassword} />
                  <Stack.Screen
                    name="DeleteAccount"
                    component={DeleteAccount}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen name="Notifications" component={Notifications} />
                  <Stack.Screen
                    name="MyProfile"
                    component={MyProfile}
                    options={{ title: `GATO | Profile` }}
                  />
                </>
              ) : (
                <>
                  <Stack.Screen name="AccountSettings" component={AccountSettingsNavigator} />
                  <Stack.Screen
                    name="MyProfile"
                    component={MyProfile}
                    options={{ title: `GATO | Profile` }}
                  />
                </>
              )}
            </>
          )}
          <Stack.Screen name="ValidateCode" component={ValidateCode} options={{ headerShown: false }} />
          <Stack.Screen name="Search" component={Search} options={{ title: `GATO | Search` }} />
          <Stack.Screen name="NotFound" component={NotFoundPage} options={{ headerShown: false }} />
        </Stack.Navigator>
        {isWebResponsive && <FiltersModal />}
        <DoubleConfirmModal />
        <SubMenuModal />
        <NotificationsModal />
        <MenuModal />
        {showAvatarModal && <ChangeAvatarWebModal />}
      </PortalProvider>
    </NavigationContainer>
  );
};
