import * as React from 'react';
import { Defs, Path, Stop, Svg, SvgProps } from 'react-native-svg';

const StartGGQ2 = ({ ...props }: SvgProps) => {
  const x = 1;
  return (
    <Svg width="28" height="29" viewBox="0 0 28 29" fill="none" {...props}>
      <Path
        d="M13.8551 14.9737C17.8468 14.9737 21.0827 14.6881 21.0827 14.3359C21.0827 13.9837 17.8468 13.6982 13.8551 13.6982C9.86335 13.6982 6.62744 13.9837 6.62744 14.3359C6.62744 14.6881 9.86335 14.9737 13.8551 14.9737Z"
        fill="#F2F2F2"
      />
      <Path
        d="M13.8552 21.8126C14.1957 21.8126 14.4717 18.4651 14.4717 14.3357C14.4717 10.2064 14.1957 6.85889 13.8552 6.85889C13.5148 6.85889 13.2388 10.2064 13.2388 14.3357C13.2388 18.4651 13.5148 21.8126 13.8552 21.8126Z"
        fill="#F2F2F2"
      />
      <Path
        d="M13.8577 28.671C21.511 28.671 27.7153 22.2528 27.7153 14.3355C27.7153 6.4182 21.511 0 13.8577 0C6.2043 0 0 6.4182 0 14.3355C0 22.2528 6.2043 28.671 13.8577 28.671Z"
        fill="url(#paint0_radial_141_2756)"
      />
      <Path
        d="M22.3304 23.6431C22.3899 23.6431 22.4382 23.5932 22.4382 23.5316C22.4382 23.4701 22.3899 23.4202 22.3304 23.4202C22.2709 23.4202 22.2227 23.4701 22.2227 23.5316C22.2227 23.5932 22.2709 23.6431 22.3304 23.6431Z"
        fill="url(#paint1_linear_141_2756)"
      />
      <Defs>
        <radialGradient
          id="paint0_radial_141_2756"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8577 14.3355) scale(13.8577 14.3355)">
          <Stop stopColor="#F2F2F2" />
          <Stop offset="0.04" stopColor="#F2F2F2" stopOpacity="0.89" />
          <Stop offset="0.12" stopColor="#F2F2F2" stopOpacity="0.68" />
          <Stop offset="0.2" stopColor="#F2F2F2" stopOpacity="0.5" />
          <Stop offset="0.28" stopColor="#F2F2F2" stopOpacity="0.35" />
          <Stop offset="0.37" stopColor="#F2F2F2" stopOpacity="0.22" />
          <Stop offset="0.46" stopColor="#F2F2F2" stopOpacity="0.12" />
          <Stop offset="0.56" stopColor="#F2F2F2" stopOpacity="0.05" />
          <Stop offset="0.67" stopColor="#F2F2F2" stopOpacity="0.01" />
          <Stop offset="0.81" stopColor="#F2F2F2" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_141_2756"
          x1="22.2227"
          y1="23.5316"
          x2="22.4382"
          y2="23.5316"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F2F2F2" />
          <Stop offset="1" stopColor="#F2F2F2" stopOpacity="0" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default StartGGQ2;
