import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export default StyleSheet.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: moderateScale(420),
    height: '100%',
    minHeight: moderateScale(160),
  },
  img: { width: moderateScale(150), height: moderateScale(150), borderRadius: moderateScale(8) },
  descriptionWrapper: {
    width: '58%',
    padding: moderateScale(6),
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    height: '100%',
  },
  description: { paddingVertical: moderateScale(5) },
  buttonText: { fontSize: moderateScale(12), textAlign: 'center' },
  button: {
    backgroundColor: Colors.buttonTerciary,
    borderRadius: moderateScale(10),
    width: '60%',
    alignSelf: 'flex-start',
  },
});
