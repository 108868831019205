import React, { useState } from 'react';

import SocialButton from './SocialButton';
import styles from './styles';

const SocialLoginButtons = () => {
  const [disabled, setDisabled] = useState(false);

  return (
    <div style={styles.socialLoginButtons}>
      <SocialButton provider="google" disabled={disabled} setDisabled={setDisabled} />
      {/* <SocialButton provider="facebook" disabled={disabled} setDisabled={setDisabled} /> */}
      <SocialButton provider="apple" disabled={disabled} setDisabled={setDisabled} />
    </div>
  );
};

export default SocialLoginButtons;
