import { StyleSheet } from 'react-native';

import { SCREEN_WIDTH } from '@constants/platform';
import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH * 5,
    paddingVertical: moderateScale(8),
    overflow: 'hidden',
    flexDirection: 'row',
    position: 'relative',
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  spark: {
    marginHorizontal: Spacing.SMALL,
  },
  awards: {
    width: moderateScale(118),
    height: moderateScale(42),
  },
  gameQuest: {
    width: moderateScale(150.95),
    height: moderateScale(22.15),
  },
  christmasStar: { width: 31, height: 31 },
  chip: {
    paddingHorizontal: moderateScale(10),
    paddingVertical: moderateScale(5),
    borderRadius: moderateScale(30),
    position: 'relative',
    overflow: 'hidden',
  },
});
