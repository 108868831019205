import * as React from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

const GoogleButtonIcon = (props: SvgProps) => (
  <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <Path
      d="M12.334 13.5V10.5L22.2222 10.5C22.2958 10.9893 22.334 11.4902 22.334 12C22.334 17.5228 17.8568 22 12.334 22C6.81114 22 2.33398 17.5228 2.33398 12C2.33398 6.47715 6.81114 2 12.334 2C15.0954 2 17.5954 3.11929 19.4051 4.92893L17.2837 7.05025C16.017 5.7835 14.267 5 12.334 5C8.46799 5 5.33398 8.13401 5.33398 12C5.33398 15.866 8.46799 19 12.334 19C15.6851 19 18.4862 16.6452 19.1729 13.5H12.334Z"
      fill="white"
    />
  </Svg>
);

export default GoogleButtonIcon;
