import { StyleSheet } from 'react-native';

import { SCREEN_WIDTH, isMobile, isWebResponsive } from '@constants/platform';
import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

const ICON_DIMENTIONS = moderateScale(40);

export const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: moderateScale(20),
    left: moderateScale(20),
    zIndex: 3,
    width: isMobile ? SCREEN_WIDTH : undefined,
  },
  loading: {
    position: 'absolute',
    left: isWebResponsive ? '43%' : '50%',
    top: isWebResponsive ? '43%' : '50%',
    zIndex: 999,
  },
  left: {
    width: ICON_DIMENTIONS,
    height: ICON_DIMENTIONS,
    borderRadius: ICON_DIMENTIONS / 2,
    backgroundColor: Colors.greyTwo,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backInRight: {
    left: SCREEN_WIDTH - moderateScale(60),
    top: moderateScale(12),
  },
  iframe: {
    borderWidth: 0,
  },
});
