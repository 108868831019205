import React from 'react';
import { useNavigation } from '@react-navigation/core';
import { Image, View, useWindowDimensions } from 'react-native';

import CustomText from '@atoms/CustomText';
import Button from '@atoms/Button';
import { rootProp } from 'app/navigation/types';
import { useTranslations } from '@hooks/useTranslation';
import BG from '@assets/img/404/background.png';
import AstroCAT from '@assets/img/404/GATO.png';
import ObjectsPNG from '@assets/img/404/objects.png';
import earthPNG from '@assets/img/404/earth.png';
import starsBG from '@assets/img/404/stars.png';
import { isTablet, isWebResponsive } from '@constants/platform';

import styles from './styles';

export const NotFoundPage = (): JSX.Element => {
  // constants
  const { height, width } = useWindowDimensions();
  // states

  // hooks
  const i18n = useTranslations();
  const { reset } = useNavigation<rootProp>();
  // functions
  function handleNavigate(): void {
    reset({
      index: 0,
      routes: [{ name: 'Home' }],
    });
  }
  // effects

  // render
  return (
    <View style={styles.body}>
      <Image
        source={BG}
        style={[
          {
            width,
            height,
          },
          styles.background,
        ]}
      />
      <Image
        source={starsBG}
        style={[
          {
            width,
            height,
          },
          styles.backgroundSTARS,
        ]}
      />
      <View style={styles.PNGWrapper}>
        <Image source={ObjectsPNG} style={styles.objectsPNG} />
        <Image source={AstroCAT} style={styles.astroCAT} />
        <Image source={earthPNG} style={styles.earthPNG} />
      </View>
      <CustomText
        size="xlarge"
        color="limeGreen"
        numberOfLines={2}
        customStyle={{ alignSelf: 'center' }}
        align="center">
        {i18n.t('404.title_1')}
        {isWebResponsive || isTablet ? <br /> : ' '}
        {i18n.t('404.title_2')}
      </CustomText>
      <Button onPress={handleNavigate} terciary customStyle={styles.btn} customTextStyle={styles.btnText}>
        {i18n.t('404.go_back')}
      </Button>
    </View>
  );
};
