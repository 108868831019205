import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  card: {
    marginTop: moderateScale(25),
    backgroundColor: Colors.darkGrey,
    borderRadius: moderateScale(16),
    paddingVertical: moderateScale(24),
    paddingHorizontal: moderateScale(48),
    width: '30%',
    minWidth: moderateScale(274),
    minHeight: moderateScale(120),
    zIndex: 1,
  },
  title: { marginVertical: moderateScale(8) },
  value: {
    width: '85%',
    alignSelf: 'center',
  },
});
