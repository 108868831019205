import * as React from 'react';
import { Path, Svg, SvgProps } from 'react-native-svg';

const FacebookButtonIcon = (props: SvgProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M18 3H15C12.2386 3 10 5.23858 10 8V10H6V14H10V21H14V14H18V10H14V8C14 7.44772 14.4477 7 15 7H18V3Z"
      fill="white"
    />
  </Svg>
);

export default FacebookButtonIcon;
