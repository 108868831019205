import React, { FC } from 'react';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { numberToPlayedTime } from '@utils/numberToTimePlayed';
import { numberToCurrency } from '@utils/numberToCurrency';
import { nFormatter } from '@utils/nFormatter';
import { IS_HIGH_SCREEN } from '@constants/platform';

import { styles } from './styles';
import { BalanceStatsEnum, IEarningStatCardProps } from './types';

export const EarningStatCard: FC<IEarningStatCardProps> = ({
  title,
  value = 0,
  format = BalanceStatsEnum.DECIMAL_NUMBER,
}): JSX.Element => {
  // functions
  function valueFormat(): string {
    if (format === BalanceStatsEnum.DECIMAL_NUMBER) {
      return nFormatter(Math.round(value), 2);
    }
    if (format === BalanceStatsEnum.TIME_NUMBER) {
      return numberToPlayedTime(Math.round(value));
    }
    if (format === BalanceStatsEnum.CURRENCY_NUMBER) {
      return numberToCurrency(Number(value));
    }
    return value.toString();
  }
  // effects

  // render
  return (
    <View style={styles.card}>
      <CustomText
        size={IS_HIGH_SCREEN ? 'xbig' : 'msmall'}
        customStyle={styles.title}
        weight="interBold"
        numberOfLines={1}
        align="center">
        {title}
      </CustomText>
      <CustomText size="xlarge" numberOfLines={1} customStyle={styles.value} align="center">
        {valueFormat()}
      </CustomText>
    </View>
  );
};
