export const millisecondsToMinutesAndSeconds = (ms: number): string => {
  const minutes = Math.floor(ms / 60000);
  const seconds = parseInt(((ms % 60000) / 1000).toFixed(0), 10);

  const concat = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return concat ?? '';
};

export const formatTime = (time: number): string => {
  if (time <= 0) return '0:00';

  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};
