import React, { useState } from 'react';
import { FlatList, Pressable, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import { MightGameCard } from '@molecules/MightGameCard';
import CustomText from '@atoms/CustomText';
import { moderateScale } from '@utils/scaling';
import { useTranslations } from '@hooks/useTranslation';
import { LATERALPLUS } from 'app/theme/spacing';
import { Game } from '@interfaces/gameDetail';
import { MightGameCardResponsive } from '@molecules/MightGameCardResponsive';
import { isMobileBrowser } from '@utils/isMobileBrowser';
import DownArrow from '@assets/svg/DownArrow';
import useScreenOrientation, { OrientationEnum } from '@hooks/useOrientation';
import { Colors } from '@theme';

import { GamesPostPlayProps } from './types';
import styles from './styles';

const DEFAULT_OFFSET_VALUE = 0; // For portrait mode
const INITIAL_OFFSET_VALUE = -200; // For landscape mode

const GamesPostPlay = ({ games }: GamesPostPlayProps) => {
  // States
  const [recommendationOffsetY, setRecommendationsOffsetY] = useState<number>(INITIAL_OFFSET_VALUE);
  // Hooks
  const i18n = useTranslations();
  const orientation = useScreenOrientation();
  // constants
  const isOpen = recommendationOffsetY === DEFAULT_OFFSET_VALUE;

  // functions
  function handleToggleRecommendations(): void {
    if (isOpen) {
      setRecommendationsOffsetY(INITIAL_OFFSET_VALUE); // Close
    } else {
      setRecommendationsOffsetY(DEFAULT_OFFSET_VALUE); // Open
    }
  }
  // render
  if (isMobileBrowser) {
    return (
      <View
        style={[
          styles.mainContainerResponsive,
          {
            bottom: moderateScale(
              orientation === OrientationEnum.LANDSCAPE ? recommendationOffsetY : DEFAULT_OFFSET_VALUE
            ),
            backgroundColor:
              orientation === OrientationEnum.LANDSCAPE ? Colors.blackThree : Colors.transparentWhite,
          },
        ]}>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '95%',
          }}>
          <CustomText customStyle={styles.title} size="xmedium" align="left">
            {i18n.t('game_page.recommended_games')}
          </CustomText>
          {orientation === OrientationEnum.LANDSCAPE ? (
            <Pressable
              onPress={handleToggleRecommendations}
              style={[
                styles.downArrow,
                {
                  transform: [{ rotate: isOpen ? '0deg' : '180deg' }],
                },
              ]}>
              <DownArrow />
            </Pressable>
          ) : null}
        </View>
        <FlatList
          decelerationRate="fast"
          snapToInterval={(moderateScale(420) + LATERALPLUS) * 2}
          horizontal
          scrollEventThrottle={16}
          contentContainerStyle={styles.list}
          showsHorizontalScrollIndicator={false}
          data={games.slice(0, 3) || []}
          renderItem={({ item }: { item: Game }) => (
            <View style={styles.itemWrapper} key={item.id}>
              <MightGameCardResponsive {...item} />
            </View>
          )}
        />
      </View>
    );
  }

  return (
    <LinearGradient
      colors={[Colors.black, Colors.transparentWhite]}
      start={{ x: 0, y: 1 }}
      end={{ x: 0, y: 0 }}
      locations={[0, 1]}
      style={{ position: 'absolute', width: '100%', height: '40%', bottom: 0, left: 0 }}>
      <View style={styles.mainContainer}>
        <CustomText customStyle={styles.title} size="xmedium" align="left">
          {i18n.t('game_page.recommended_games')}
        </CustomText>
        <FlatList
          decelerationRate="fast"
          snapToInterval={(moderateScale(420) + LATERALPLUS) * 2}
          horizontal
          scrollEventThrottle={16}
          contentContainerStyle={styles.list}
          showsHorizontalScrollIndicator={false}
          data={games.slice(0, 3) || []}
          renderItem={({ item }: { item: Game }) => (
            <View style={styles.itemWrapper} key={item.id}>
              <MightGameCard {...item} />
            </View>
          )}
        />
      </View>
    </LinearGradient>
  );
};

export default GamesPostPlay;
