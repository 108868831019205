import React, { FC, useEffect, useState } from 'react';
import { Linking, Pressable, View } from 'react-native';
import i18next from 'i18next';

import { isWebResponsive } from '@constants/platform';
import { Spacing } from '@theme';
import { useGameEvents } from '@services/useGameEvents';
import { EventNameEnum } from '@interfaces/event';
import FastImage from '@atoms/FastImage';
import links from '@constants/links';

import { styles } from './styles';
import { AddBannerProps } from './types';

const GameQuestBanner: FC<AddBannerProps> = ({ bannerDimentions, customStyles, gameId, gameSlug }) => {
  // states
  const [isGameOnGameQuest, setIsGameOnGameQuest] = useState<boolean>(false);
  // hooks
  const { data } = useGameEvents(gameId);
  const { language } = i18next;

  useEffect(() => {
    if (data && data.gameEvents.length > 0) {
      for (const event of data.gameEvents) {
        if (event.event.name === EventNameEnum.GATO_GAME_QUEST) {
          setIsGameOnGameQuest(true);
          break;
        }
      }
    }
  }, [data]);

  const handleAdClick = () => {
    Linking.openURL(links.gameQuestVoteLink + gameSlug);
  };

  const getImage = () => {
    if (isWebResponsive) {
      if (language.toLocaleLowerCase() === 'es') {
        return links.gameQuestGamePageBannerES;
      }
      return links.gameQuestGamePageBannerEN;
    }
    if (language.toLocaleLowerCase() === 'es') {
      return links.gameQuestGamePageBannerESDesktop;
    }
    return links.gameQuestGamePageBannerENDesktop;
  };

  // render
  if (isGameOnGameQuest) {
    return (
      <View style={{ marginBottom: Spacing.XLARGE }}>
        <Pressable
          onPress={handleAdClick}
          style={[
            styles.container,
            customStyles,
            {
              width: bannerDimentions?.width || '100%',
              height: bannerDimentions?.height || '100%',
            },
          ]}>
          <FastImage source={{ uri: getImage() }} style={styles.image} resizeMode="contain" />
        </Pressable>
      </View>
    );
  }
  return <View />;
};
export default GameQuestBanner;
