/* eslint-disable global-require */
import { moderateScale } from '@utils/scaling';

export const SIZES = {
  xSmall: moderateScale(10),
  mSmall: moderateScale(12),
  small: moderateScale(13),
  medium: moderateScale(14),
  lMedium: moderateScale(16),
  xMedium: moderateScale(18),
  big: moderateScale(20),
  mBig: moderateScale(22),
  xBig: moderateScale(24),
  large: moderateScale(28),
  mlarge: moderateScale(30),
  xLarge: moderateScale(34),
  xxLarge: moderateScale(38),
  xxxLarge: moderateScale(40),
  giant: moderateScale(65),
};

export const INTER_BLACK = 'InterTight-Black';
export const INTER_BOLD_ITA = 'InterTight-BoldItalic';
export const INTER_BOLD = 'InterTight-Bold';
export const INTER_SEMI_ITA = 'InterTight-SemiBoldItalic';
export const INTER_SEMI = 'InterTight-SemiBold';
export const INTER_MEDIUM_ITA = 'InterTight-MediumItalic';
export const INTER_MEDIUM = 'InterTight-Medium';
export const INTER_REGULAR_ITA = 'InterTight-Italic';
export const INTER_REGULAR = 'InterTight-Regular';
export const OSWALD_BOLD = 'Oswald-Bold';
export const OSWALD_SEMI = 'Oswald-SemiBold';
export const OSWALD_REGULAR = 'Oswald-Regular';

export const FONTS_IMPORTS = {
  [INTER_BLACK]: require('../assets/fonts/InterTight-Black.ttf'),
  [INTER_BOLD_ITA]: require('../assets/fonts/InterTight-BoldItalic.ttf'),
  [INTER_BOLD]: require('../assets/fonts/InterTight-Bold.ttf'),
  [INTER_SEMI_ITA]: require('../assets/fonts/InterTight-SemiBoldItalic.ttf'),
  [INTER_SEMI]: require('../assets/fonts/InterTight-SemiBold.ttf'),
  [INTER_MEDIUM_ITA]: require('../assets/fonts/InterTight-MediumItalic.ttf'),
  [INTER_MEDIUM]: require('../assets/fonts/InterTight-Medium.ttf'),
  [INTER_REGULAR_ITA]: require('../assets/fonts/InterTight-Italic.ttf'),
  [INTER_REGULAR]: require('../assets/fonts/InterTight-Regular.ttf'),
  [OSWALD_BOLD]: require('../assets/fonts/Oswald-Bold.ttf'),
  [OSWALD_SEMI]: require('../assets/fonts/Oswald-SemiBold.ttf'),
  [OSWALD_REGULAR]: require('../assets/fonts/Oswald-Regular.ttf'),
};
