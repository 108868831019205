import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { GameEarningStats } from '@interfaces/Earnings';

async function getDashboardEarnings(): Promise<GameEarningStats> {
  const response = await axiosGamesInstance.get<GameEarningStats>(`/balance`);

  return response.data;
}

export function useDashboardEarnings() {
  return useQuery<GameEarningStats>([QueryKeys.GET_DASHBOARD_EARNINGS], () => getDashboardEarnings());
}
