import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Analytics = (props: SvgProps) => {
  // constants
  const { fill = '#777E91' } = props;
  // render
  return (
    <Svg width={18} height={18} fill="none" {...props}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M1.385.49c.328 0 .594.265.594.593V13.75a2.573 2.573 0 0 0 2.572 2.573h12.667a.594.594 0 1 1 0 1.187H4.551a3.76 3.76 0 0 1-3.76-3.76V1.083c0-.328.266-.594.594-.594ZM9.104 9a.594.594 0 0 0-1.188 0v3.958a.594.594 0 0 0 1.188 0V9Zm-3.167 3.958v-2.375a.594.594 0 0 0-1.188 0v2.375a.594.594 0 0 0 1.188 0Zm5.74-2.969c.327 0 .593.266.593.594v2.375a.594.594 0 1 1-1.187 0v-2.375c0-.328.265-.594.593-.594Zm3.76 2.969V9a.594.594 0 1 0-1.188 0v3.958a.594.594 0 0 0 1.188 0Zm-.174-8.288a.594.594 0 1 0-.84-.84l-2.047 2.047a.99.99 0 0 1-1.4 0l-.927-.928a2.177 2.177 0 0 0-3.079 0L4.923 6.997a.594.594 0 0 0 .84.84L7.81 5.788a.99.99 0 0 1 1.4 0l.927.928c.85.85 2.229.85 3.079 0l2.047-2.047Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
export default Analytics;
