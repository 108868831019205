function isFirefox() {
  // Check the userAgent string
  const { userAgent } = navigator;
  const firefoxRegex = /Firefox\/([0-9]+(\.[0-9]+)*)/;
  const match = userAgent.match(firefoxRegex);

  if (match) {
    return true;
  }

  return false;
}

export { isFirefox };
