import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

const styles = StyleSheet.create({
  socialLoginButton: {
    width: isWebResponsive ? moderateScale(93) : moderateScale(165),
    height: moderateScale(48),
    padding: moderateScale(8),
    color: Colors.white,
    backgroundColor: Colors.blackThree,
    border: `0.5px solid ${Colors.white}`,
  },
  socialLoginButtonText: {
    fontSize: moderateScale(16),
  },
});

export default styles;
