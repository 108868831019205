import { useMutation } from 'react-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

import { axiosAuthInstance } from '@config/api';
import { DeleteDeviceTokenParams, LoginParams, LoginResponse, RefreshParams } from '@interfaces/auth';
import { isMobile } from '@constants/platform';

async function loginService(data: LoginParams) {
  const deviceToken = await AsyncStorage.getItem('deviceToken');
  const metaData = { deviceToken: deviceToken || '1', operatingSystem: Platform.OS.toUpperCase() };

  const response = await axiosAuthInstance.post<LoginResponse>('/auth/login', {
    ...data,
    ...(isMobile && metaData),
  });
  return response.data;
}

export function useLogin() {
  return useMutation((data: LoginParams) => loginService(data));
}

async function refreshToken(data: RefreshParams) {
  const deviceToken = await AsyncStorage.getItem('deviceToken');
  const metaData = { deviceToken: deviceToken || '1', operatingSystem: Platform.OS.toUpperCase() };

  const response = await axiosAuthInstance.post('/auth/refreshToken', {
    ...data,
    ...(isMobile && metaData),
  });
  return response.data;
}

export function useRefreshToken() {
  return useMutation((data: RefreshParams) => refreshToken(data));
}

async function logOutService(data: DeleteDeviceTokenParams) {
  const response = await axiosAuthInstance.delete<DeleteDeviceTokenParams>('/users/deviceToken', { data });

  return response.data;
}

export function useLogout() {
  return useMutation((data: DeleteDeviceTokenParams) => logOutService(data));
}

async function loginWithApple(data: any) {
  const response = await axiosAuthInstance.post('/auth/apple/redirect', data);

  return response.data;
}

type AppleLoginPayload = {
  code: string;
  idToken: string;
  state: string;
  nonce: string;
  user: any;
};

export function useLoginWithApple() {
  return useMutation((data: AppleLoginPayload) => loginWithApple(data));
}
